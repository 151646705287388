import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            imagePath: this.$imageURL + 'advertise/'
        }
    },
    created() {
        sessionStorage.setItem('isAdvertise', false);
    },
    mounted() {
        this.loadJSCSS();
    },
    methods: {
        setIsAdvertise() {
            this.state.isAdvertise = false;
        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/home_1.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/carousel-home.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
    }
}