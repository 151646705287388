<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Product Details</a></li>
            <li>Page active</li>
          </ul>
        </div>
        <h1>{{ productDetails.name }}</h1>
      </div>
      <!-- /page_header -->
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div
            class="owl-carousel owl-theme prod_pics magnific-gallery"
            v-if="gallery"
          >
            <div class="item" v-for="(data, index) in gallery" :key="index">
              <a
                :href="imagePath + data.photoUrl"
                title="Photo title"
                data-effect="mfp-zoom-in"
                ><img :src="imagePath + data.photoUrl" alt=""
              /></a>
            </div>
            <!-- /item -->
          </div>
          <div v-else class="owl-carousel owl-theme prod_pics magnific-gallery">
            <div class="item">
              <img :src="imagePath + 'default.png'" :alt="'default.png'" />
            </div>
          </div>
          <!-- /carousel -->
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->

    <div class="bg_white">
      <div class="container margin_60_35">
        <div class="row justify-content-between">
          <div class="col-lg-6">
            <div class="prod_info version_2">
              <span class="rating">
                <i
                  class="icon-star voted"
                  v-for="index in voted"
                  :key="index"
                ></i>
                <!-- <i class="icon-star voted"></i>
                <i class="icon-star voted"></i>
                <i class="icon-star voted"></i> -->
                <i class="icon-star" v-for="index in notVoted" :key="index"></i>
                <em>{{ productRating }}</em>
                <p>{{ reviewListData.length }} reviews</p>
              </span>
              <p>
                <small>SKU: {{ productDetails.sku }}</small>
              </p>
              <br />
              <p v-html="productDetails.shortDescription"></p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="prod_options version_2">
              <!-- <div class="row">
                <label class="col-xl-7 col-lg-5 col-md-6 col-6 pt-0"
                  ><strong>Color</strong></label
                >
                <div class="col-xl-5 col-lg-5 col-md-6 col-6 colors">
                  <ul>
                    <li
                      v-for="(data, index) in productDetails.colors"
                      :key="index"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="selectColor(index)"
                        :class="[isActive[index] ? 'active' : '', 'color']"
                        :style="{ 'background-color': data.colorName }"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <label class="col-xl-7 col-lg-5 col-md-6 col-6"
                  ><strong>Size</strong></label
                >
                <div class="col-xl-5 col-lg-5 col-md-6 col-6">
                  <select v-model="size" class="form-control form-select">
                    <option style="display: none" selected value="">
                      Select Size
                    </option>
                    <option
                      v-for="(data, index) in productDetails.sizes"
                      :key="index"
                      :value="data.sizeName"
                    >
                      {{ data.sizeName }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="row">
                <label class="col-xl-7 col-lg-5 col-md-6 col-6"
                  ><strong>Quantity</strong></label
                >
                <div class="col-xl-5 col-lg-5 col-md-6 col-6">
                  <div class="numbers-row">
                    <input
                      type="text"
                      :value="quantity"
                      id="quantity_1"
                      class="qty2"
                      name="quantity_1"
                    />
                    <div class="inc button_inc" @click="increaseQuantity()">
                      +
                    </div>
                    <div class="dec button_inc" @click="deductQuantity()">
                      -
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-7 col-md-6">
                  <div class="price_main">
                    <span class="new_price"
                      >${{
                        productDetails.discountedPrice
                          ? productDetails.discountedPrice
                          : productDetails.price
                      }}</span
                    >

                    <span
                      class="percentage"
                      v-if="productDetails.discountTitle"
                      >{{ productDetails.discountTitle }}</span
                    >
                    <span
                      class="old_price"
                      v-if="productDetails.discountedPrice"
                      >${{ productDetails.price }}</span
                    >
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div class="btn_add_to_favourites mb-2">
                    <a
                      href="javascript:void(0)"
                      class="btn_1"
                      v-on:click="processWishListData(productDetails)"
                      >Add to Favorite</a
                    >
                  </div>
                  <div class="btn_add_to_cart">
                    <a
                      href="javascript:void(0)"
                      class="btn_1"
                      v-on:click="processCartData(productDetails)"
                      >Add to Cart</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /bg_white -->

    <div class="tabs_product bg_white version_2">
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              id="tab-A"
              href="#pane-A"
              class="nav-link active"
              data-bs-toggle="tab"
              role="tab"
              >Description</a
            >
          </li>
          <li class="nav-item">
            <a
              id="tab-B"
              href="#pane-B"
              class="nav-link"
              data-bs-toggle="tab"
              role="tab"
              >Reviews</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- /tabs_product -->

    <div class="tab_content_wrapper">
      <div class="container">
        <div class="tab-content" role="tablist">
          <div
            id="pane-A"
            class="card tab-pane fade active show"
            role="tabpanel"
            aria-labelledby="tab-A"
          >
            <div class="card-header" role="tab" id="heading-A">
              <h5 class="mb-0">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#collapse-A"
                  aria-expanded="false"
                  aria-controls="collapse-A"
                >
                  Description
                </a>
              </h5>
            </div>

            <div
              id="collapse-A"
              class="collapse"
              role="tabpanel"
              aria-labelledby="heading-A"
            >
              <div class="card-body">
                <div class="row justify-content-between">
                  <div class="col-lg-6">
                    <h3>Details</h3>
                    <p v-if="!productDetails.longDescription">N/a</p>
                    <p v-else v-html="productDetails.longDescription"></p>
                  </div>
                  <div class="col-lg-5">
                    <h3>Specifications</h3>
                    <div class="table-responsive">
                      <table class="table table-sm table-striped">
                        <tbody>
                          <!-- <tr>
                            <td><strong>Color</strong></td>
                            <td>{{ colorList }}</td>
                          </tr>
                          <tr>
                            <td><strong>Size</strong></td>
                            <td>{{ sizeList }}</td>
                          </tr> -->
                          <tr>
                            <td><strong>Weight</strong></td>
                            <td>{{ productDetails.weight }}</td>
                          </tr>
                          <tr>
                            <td><strong>Brand</strong></td>
                            <td>{{ productDetails.brandName }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- /table-responsive -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /TAB A -->
          <div
            id="pane-B"
            class="card tab-pane fade"
            role="tabpanel"
            aria-labelledby="tab-B"
          >
            <div class="card-header" role="tab" id="heading-B">
              <h5 class="mb-0">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#collapse-B"
                  aria-expanded="false"
                  aria-controls="collapse-B"
                >
                  Reviews
                </a>
              </h5>
            </div>
            <div
              id="collapse-B"
              class="collapse"
              role="tabpanel"
              aria-labelledby="heading-B"
            >
              <div class="card-body">
                <div class="row justify-content-between">
                  <div
                    class="col-lg-5"
                    v-for="(data, index) in reviewListData"
                    :key="index"
                  >
                    <div class="review_content">
                      <div class="clearfix add_bottom_10">
                        <span class="rating"
                          ><i
                            class="icon-star"
                            v-for="index in parseInt(data.rating)"
                            :key="index"
                          ></i
                          ><em>{{ data.rating }}/5</em></span
                        >
                        <em
                          >Published
                          {{ $filters.formatReviewDate(data.createdAt) }}</em
                        >
                      </div>
                      <h4>"{{ data.reviewTitle }}"</h4>
                      <p>{{ data.reviewDescription }}</p>
                    </div>
                  </div>
                </div>
                <p class="text-end">
                  <a
                    href="javascript:void(0)"
                    @click="leaveReview"
                    class="btn_1"
                    >Leave a review</a
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- /tab B -->
        </div>
        <!-- /tab-content -->
      </div>
      <!-- /container -->
    </div>
    <!-- /tab_content_wrapper -->

    <div class="bg_white">
      <div
        class="container margin_60_35"
        v-if="relatedProduct && relatedProduct.length > 0"
      >
        <div class="main_title">
          <h2>{{ pageCMS.pageTitle }}</h2>
          <span>{{ pageCMS.backgroundText }}</span>
          <p>{{ pageCMS.pageSubTitle }}</p>
        </div>
        <div class="owl-carousel owl-theme products_carousel">
          <div
            class="item"
            v-for="(data, index) in relatedProduct"
            :key="index"
          >
            <div class="grid_item">
              <span class="ribbon off" v-if="data.discountTitle">{{
                data.discountTitle
              }}</span>
              <figure>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/product-details/' + data.id)"
                >
                  <img
                    class="owl-lazy"
                    :src="imagePath + data.featuredImage"
                    :data-src="imagePath + data.featuredImage"
                    alt=""
                  />
                </a>
              </figure>
              <div class="rating">
                <i class="icon-star voted"></i><i class="icon-star voted"></i
                ><i class="icon-star voted"></i><i class="icon-star voted"></i
                ><i class="icon-star"></i>
              </div>
              <a
                href="javascript:void(0)"
                @click="$router.push('/product-details/' + data.id)"
              >
                <h3>{{ data.name }}</h3>
              </a>
              <div class="price_box">
                <span class="new_price"
                  >${{
                    data.discountedPrice ? data.discountedPrice : data.price
                  }}</span
                >
              </div>
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    class="tooltip-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Add to favorites"
                    ><i class="ti-heart"></i><span>Add to favorites</span></a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    class="tooltip-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Add to cart"
                    ><i class="ti-shopping-cart"></i><span>Add to cart</span></a
                  >
                </li>
              </ul>
            </div>
            <!-- /grid_item -->
          </div>
          <!-- /item -->
          <!-- /item -->

          <!-- /item -->
        </div>
        <!-- /products_carousel -->
      </div>
      <!-- /container -->
    </div>
    <!-- /bg_white -->
  </main>
  <Advertise
    v-if="
      state.advertiseList.includes('ProductDetails') &&
      state.isAdvertise == true
    "
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('ProductDetails') &&
      state.isNewsLetter == true
    "
  />
</template>
<script src="../js/product-details.js"></script>