<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <!-- /top_banner -->
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">My Account</a></li>
            <li>Order detail</li>
          </ul>
        </div>
        <h1>Order detail</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar />
        <!-- /col -->
        <div class="col-lg-9" v-if="orderDetails">
          <section class="content-main">
            <div class="card">
              <header class="card-header">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-6 mb-lg-0 mb-15">
                    <h2 class="content-title card-title">Order detail</h2>
                    <small>Order ID: {{ orderId }}</small>
                  </div>
                  <div class="col-lg-6 col-md-6 ms-auto text-md-end">
                    <span>
                      <i class="material-icons md-calendar_today"></i>
                      <b>{{ $filters.formatDate(orderDetails.createdAt) }}</b>
                    </span>
                    <!-- <a class="btn btn-secondary print ms-2" href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 192H64C28.65 192 0 220.7 0 256v96c0 17.67 14.33 32 32 32h32v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h32c17.67 0 32-14.33 32-32V256C512 220.7 483.3 192 448 192zM384 448H128v-96h256V448zM432 296c-13.25 0-24-10.75-24-24c0-13.27 10.75-24 24-24s24 10.73 24 24C456 285.3 445.3 296 432 296zM128 64h229.5L384 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C387.4 3.375 379.2 0 370.8 0H96C78.34 0 64 14.33 64 32v128h64V64z"/></svg>
                                </a> -->
                  </div>
                </div>
              </header>
              <!-- card-header end// -->
              <div class="card-body">
                <div class="row mb-50 mt-20 order-info-wrap">
                  <!-- <div class="col-md-4">
                                <article class="icontext align-items-start invoice">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <span class="icon icon-sm rounded-circle bg-primary-light">
                                                <i class="ti-user"></i>
                                            </span>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="text">
                                                <h6 class="mb-1">Customer</h6>
                                                <p class="mb-1">
                                                    {{customerInfo.name}} <br>
                                                    {{customerInfo.email}}<br>
                                                    {{customerInfo.mobile}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div> -->
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="icon icon-sm rounded-circle bg-primary-light"
                          >
                            <i class="ti-user"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Customer</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600"
                                >Name:</span
                              >&nbsp;{{ customerInfo.name }} <br />
                              <span style="font-size: 16px; font-weight: 600"
                                >Email:</span
                              >&nbsp;{{ customerInfo.email }}<br />
                              <span style="font-size: 16px; font-weight: 600"
                                >Phone:</span
                              >&nbsp;{{ customerInfo.mobile }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="icon icon-sm rounded-circle bg-primary-light"
                          >
                            <i class="ti-shopping-cart-full"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Order Info</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600">
                                Pay method:</span
                              >&nbsp;
                              {{ orderDetails.paymentMethodName }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600">
                                Status:</span
                              >&nbsp;
                              {{ orderDetails.status }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                  <div class="col-md-4">
                    <article class="icontext align-items-start">
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="icon icon-sm rounded-circle bg-primary-light"
                          >
                            <i class="ti-bag"></i>
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="text">
                            <h6 class="mb-1">Deliver To</h6>
                            <p class="mb-1">
                              <span style="font-size: 16px; font-weight: 600"
                                >Name:</span
                              >&nbsp;{{ customerInfo.name }}
                              <br />
                              <span style="font-size: 16px; font-weight: 600"
                                >Address:</span
                              >&nbsp;
                              {{
                                customerInfo.addressOne
                                  ? customerInfo.addressOne + ", "
                                  : ""
                              }}
                              {{
                                customerInfo.city
                                  ? customerInfo.city + ", "
                                  : ""
                              }}
                              {{
                                customerInfo.country ? customerInfo.country : ""
                              }}
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <!-- col// -->
                </div>
                <!-- row // -->
                <div class="row mt-5">
                  <div class="col-lg-8">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th width="40%">Product</th>
                            <th width="20%">Unit Price</th>
                            <th width="20%">Quantity</th>
                            <th width="20%" class="text-end">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(data, index) in orders" :key="index">
                            <td>
                              <a class="itemside" href="#">
                                <div class="left">
                                  <img
                                    :src="imagePath + data.featuredImage"
                                    width="40"
                                    height="40"
                                    class="img-xs"
                                    alt="Item"
                                  />
                                </div>
                                <div class="info">{{ data.name }}</div>
                              </a>
                            </td>
                            <td>${{ data.itemPrice }}</td>
                            <td>{{ data.quantity }}</td>
                            <td class="text-end">${{ data.itemTotal }}</td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <article class="float-end">
                                <dl class="dlist">
                                  <dt>Subtotal:</dt>
                                  <dd>${{ orderDetails.subTotal }}</dd>
                                </dl>
                                <dl
                                  class="dlist"
                                  v-if="orderDetails.couponDiscount != '0'"
                                >
                                  <dt>Coupon discount:</dt>
                                  <dd>- ${{ orderDetails.couponDiscount }}</dd>
                                </dl>
                                <dl class="dlist">
                                  <dt>Grand total:</dt>
                                  <dd>
                                    ${{
                                      orderDetails.couponDiscount != "0"
                                        ? orderDetails.total -
                                          parseInt(orderDetails.couponDiscount)
                                        : orderDetails.total
                                    }}
                                  </dd>
                                </dl>
                                <dl class="dlist">
                                  <dt class="text-muted">Payment Status:</dt>
                                  <dd>
                                    <span
                                      :class="[
                                        'badge badge-pill',
                                        orderDetails.paymentStatus == 'PENDING'
                                          ? 'badge-soft-warning'
                                          : orderDetails.paymentStatus == 'Paid'
                                          ? 'badge-soft-success'
                                          : 'badge-soft-danger',
                                      ]"
                                      >{{ orderDetails.paymentStatus }}</span
                                    >
                                  </dd>
                                </dl>
                              </article>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- table-responsive// -->
                  </div>
                  <!-- col// -->
                  <!-- <div class="col-lg-1"></div> -->
                  <div class="col-lg-4 mt-5">
                    <div class="box shadow-sm bg-light">
                      <h6 class="mb-15">Payment Info</h6>
                      <!-- <p>
                                        <img src="core/assets/img/products/2.png" class="border" height="20"> Master Card **** **** 4768 <br>
                                        Business name: Grand Market LLC <br>
                                        Phone: +1 (800) 555-154-52
                                    </p> -->
                      <p>{{ orderDetails.paymentMethodName }}</p>
                    </div>
                    <div class="h-25 pt-4" v-if="orderDetails.remarks">
                      <div class="mb-3">
                        <label>Notes</label>
                        <textarea
                          class="form-control"
                          name="notes"
                          id="notes"
                          placeholder="Type some note"
                          spellcheck="false"
                          disabled="disabled"
                          v-html="orderDetails.remarks"
                        ></textarea>
                      </div>
                      <!-- <button class="btn btn-primary">Save note</button> -->
                    </div>
                  </div>
                  <!-- col// -->
                </div>
              </div>
              <!-- card-body end// -->
            </div>
            <!-- card end// -->
          </section>
        </div>
        <div class="col-lg-9" v-else>
          <p class="text-center">No Order found.</p>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
</template>
<style scoped>
@import url(../css/style.css);
</style>
<script src="../js/invoice.js"></script>