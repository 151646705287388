import { authHeader } from "../../../auth";
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            singleBlog: {},
            blogCategoryList: [],
            latestBlogs: [],
            blogTagList: [],
            blogComments: [],
            keyword: '',
            page: 1,
            limit: 6,
            totalPage: 0,
            imagePath: this.$imageURL + 'blog/',
            setURL: this.$serverURL + this.$api.blog.blogDetails + this.$route.params.id,
            params: '',
            categoryId: '',
            tag: '',
            name: '',
            email: '',
            website: '',
            comment: ''
        }

    },
    created() {
        document.title = "HGS-Blog";
        this.state.isLoading = true;
        this.scrollToTop();
    },
    async mounted() {
        let config = {
            method: "GET",
            url: this.setURL,
            headers: {
                "Authorization": authHeader()
            }
        };
        await this.getBlogData(config);
        this.loadCSS();
    },
    methods: {
        loadCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/blog.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
            this.state.isLoading = false;
        },
        sendComment: async function () {

            const re = /\S+@\S+\.\S+/;
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your name"
                });
            }
            else if (!this.email || !(re.test(this.email))) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your email"
                });
            }
            else if (!this.comment) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter your comment"
                });
            }
            else {
                let data = {
                    postId: this.singleBlog.id,
                    postTitle: this.singleBlog.title,
                    name: this.name,
                    email: this.email,
                    website: this.website,
                    comment: this.comment
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.blog.blogComment,

                };
                this.state.isLoading = true;
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {
                        this.name = "";
                        this.email = "";
                        this.website = "";
                        this.comment = "";
                        this.state.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Your message has been send."
                        });
                        this.state.isLoading = false;
                    }
                    else {
                        this.state.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        getBlogData: async function (config) {
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleBlog = await response.data.data.blogData[0];
                    this.blogCategoryList = await response.data.data.blogCategoryList;
                    this.blogTagList = await response.data.data.blogTagList;
                    this.latestBlogs = await response.data.data.latestBlogList;
                    this.blogComments = await response.data.data.blogComments;
                    this.state.isLoading = false;
                }
                this.state.isLoading = false;
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
    }
}