import siderbar from '../template/SideBar';
import { authHeader } from '@/auth';
export default {
    components: {
        siderbar
    },
    data() {
        return {
            orders: [],
            totalOrders: null,
            totalDeliveredOrders: null,
            totalShippedOrders: null,
            totalCancelledOrders: null,
            totalPendingOrders: null,
        }
    },
    created() {
        this.scrollToTop();
    },
    async mounted() {
        await this.getDashboardData();
    },
    methods: {
        getDashboardData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.dashboard.dashboardAPI,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.orders = response.data.recentOrdersList;
                        this.totalOrders = response.data.totalOrders;
                        this.totalDeliveredOrders = response.data.totalDeliveredOrders;
                        this.totalShippedOrders = response.data.totalShippedOrders;
                        this.totalCancelledOrders = response.data.totalCancelledOrders;
                        this.totalPendingOrders = response.data.totalPendingOrders;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
}