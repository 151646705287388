import { createRouter, createWebHistory } from 'vue-router'
import Guard from '@/guard';
import Home from '../components/Home/template/Home';
import Blog from '../components/Blog/template/Blog';
import BlogDetails from '../components/Blog/template/BlogDetails';
import Product from '../components/Product/template/Product';
import ProductDetails from '../components/Product/template/ProductDetails';
import ProductReview from '../components/Product/template/ProductReview';
import Cart from '../components/Cart/template/Cart';
import Wishlist from '../components/Wishlist/template/Wishlist';
import TrackOrder from '../components/TrackOrder/template/TrackOrder';
import Checkout from '../components/Checkout/template/Checkout';
import AboutUs from '../components/About/template/AboutUs';
import Help from '../components/Help/template/Help';
import Contact from '../components/Contact/template/Contact';
import Account from '../components/Auth/template/Account';
import ResetPassword from '../components/Auth/template/ResetPassword';
import NotFoundPage from '../components/404Page/template/404Page';
import Profile from '../components/Dashboard/template/Profile';
import Dashboard from '../components/Dashboard/template/Dashboard';
import PendingOrders from '../components/Dashboard/template/PendingOrders';
import ProcessingOrders from '../components/Dashboard/template/ProcessingOrders';
import ShippedOrders from '../components/Dashboard/template/ShippedOrders';
import CancelledOrders from '../components/Dashboard/template/CancelledOrders';
import TotalOrders from '../components/Dashboard/template/TotalOrders';
import DeliveredOrders from '../components/Dashboard/template/DeliveredOrders';
import ChangePassword from '../components/Dashboard/template/ChangePassword';
import Invoice from '../components/Dashboard/template/Invoice';
import PrivacyPolicy from "../components/Policies/template/PrivacyPolicy"
import ReturnPolicy from "../components/Policies/template/ReturnPolicy"
import CancellationPolicy from "../components/Policies/template/CancellationPolicy"
import TeramsAndCondition from "../components/Policies/template/TeramsAndCondition";
import Location from "../components/location/template/Location";
import LocationDetails from "../components/location/template/LocationDetails";
import Franchise from "../components/Franchise/template/Franchise";

const routes = [
  { path: '/', name: "Home", component: Home },
  { path: '/account', name: "Account", component: Account },
  { path: '/reset-password/:token', name: "ResetPassword", component: ResetPassword },
  { path: '/about-us', name: "AboutUs", component: AboutUs },
  { path: '/help', name: "Help", component: Help },
  { path: '/contact', name: "Contact", component: Contact },
  { path: '/blog', name: "Blog", component: Blog },
  { path: '/blog-details/:id', name: "BlogDetails", component: BlogDetails },
  { path: '/products', name: "Product", component: Product },
  { path: '/product-details/:id', name: "ProductDetails", component: ProductDetails },
  { path: '/product-review/:productId', name: "ProductReview", component: ProductReview },
  { path: '/cart', name: "Cart", component: Cart, beforeEnter: Guard },
  { path: '/wishlist', name: "Wishlist", component: Wishlist, beforeEnter: Guard },
  { path: '/checkout', name: "Checkout", component: Checkout, beforeEnter: Guard },
  { path: '/track-order', name: "TrackOrder", component: TrackOrder, beforeEnter: Guard },
  { path: '/profile', name: "Profile", component: Profile, beforeEnter: Guard },
  { path: '/dashboard', name: "Dashboard", component: Dashboard, beforeEnter: Guard },
  { path: '/pending-orders', name: "PendingOrders", component: PendingOrders, beforeEnter: Guard },
  { path: '/processing-orders', name: "ProcessingOrders", component: ProcessingOrders, beforeEnter: Guard },
  { path: '/shipped-orders', name: "ShippedOrders", component: ShippedOrders, beforeEnter: Guard },
  { path: '/cancelled-orders', name: "CancelledOrders", component: CancelledOrders, beforeEnter: Guard },
  { path: '/total-orders', name: "TotalOrders", component: TotalOrders, beforeEnter: Guard },
  { path: '/delivered-orders', name: "DeliveredOrders", component: DeliveredOrders, beforeEnter: Guard },
  { path: '/change-password', name: "ChangePassword", component: ChangePassword, beforeEnter: Guard },
  { path: '/order-details/:id', name: "Invoice", component: Invoice, beforeEnter: Guard },
  { path: '/franchise', name: "Franchise", component: Franchise },

  { path: '/404-not-found', name: "404Page", component: NotFoundPage },
  { path: '/:pathMatch(.*)*', redirect: { name: '404Page' } },

  //policies
  { path: '/privacy-policy', name: "PrivacyPolicy", component: PrivacyPolicy },
  { path: '/return-policy', name: "ReturnPolicy", component: ReturnPolicy },
  { path: '/cacellation-policy', name: "CancellationPolicy", component: CancellationPolicy },
  { path: '/terms-conditions', name: "TermsAndCondition", component: TeramsAndCondition },

  { path: '/location', name: "Location", component: Location },
  { path: '/location-details/:id', name: "LocationDetails", component: LocationDetails }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
