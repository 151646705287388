<template>
  <main class="bg_gray">
    <div class="top_banner">
      <div
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.3)"
      >
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)">Our Location</a>
              </li>
            </ul>
          </div>
          <h1>{{ state.locationCms.locationPageTitle }}</h1>
        </div>
      </div>
      <img
        :src="imageURL + state.locationCms.backgroundImage"
        class="img-fluid"
        alt=""
      />
    </div>
    <!-- <div
      class="container margin_60"
      :style="{
        'background-image': `url(${imageURL}${state.locationCms.backgroundImage})`,
      }"
    >
      <div class="main_title" style="margin-bottom: 35px; margin-top: 35px">
        <h2>Our Location</h2>
      </div>
    </div> -->
    <div class="bg_white">
      <div class="container py-5">
        <div
          class="row justify-content-center gy-3 gx-3"
          style="padding-top: 35px; padding-bottom: 35px"
        >
          <div
            v-for="(data, index) in dataList"
            :key="index"
            class="col-12 col-sm-6 col-md-5 col-lg-4"
          >
            <div class="card shadow-sm mb-3">
              <iframe
                :src="data.mapLink"
                width="100%"
                height="330"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                aria-hidden="false"
                tabindex="0"
                class="card-img-top"
                style="border: 0"
              ></iframe>
              <div class="card-body d-flex flex-column">
                <h5 class="card-title">
                  <span>
                    <i aria-hidden="true" class="fa fa-home"></i>
                  </span>
                  {{ data.location }}
                </h5>
                <ul class="list-unstyled mt-1 mb-1">
                  <li>
                    <a
                      :href="'tel:' + data.contactNumber"
                      class="Phone"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i
                          aria-hidden="true"
                          class="fa fa-phone-square"
                        ></i> </span
                      >&nbsp;{{ data.contactNumber }}</a
                    >
                  </li>
                  <li>
                    <a
                      :href="'mailto:' + data.email"
                      class="Email"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i aria-hidden="true" class="fa fa-envelope"></i> </span
                      >&nbsp;{{ data.email }}
                    </a>
                  </li>
                  <li>
                    <!-- <a style="color: black; text-decoration: none">
                      <span><i class="fa fa-clock-o"></i></span> &nbsp;
                      {{ data.timeFrame }}
                    </a> -->
                    <a
                      href="javascript:void(0)"
                      class="Email"
                      style="color: black; text-decoration: none"
                    >
                      <span data-v-1e6215ab="">
                        <i aria-hidden="true" class="fa fa-clock-o"></i> </span
                      >&nbsp;{{ data.timeFrame }}
                    </a>
                  </li>
                </ul>
                <a
                  href="javascript:"
                  @click="$router.push('/location-details/' + data.id)"
                  class="btn_1 full-width"
                  >Show Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script src="../js/location.js">
</script>

<style scoped>
</style>
