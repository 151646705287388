import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';
import { countries } from "../../../../config/country";
import $ from 'jquery';
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            products: [],
            getAddressURL: this.$serverURL + this.$api.profile.getShippingBillingAddress,
            countriesList: countries,
            country: {
                name: 'United States of America',
                code: 'US',
            },
            //shipping data
            shippingAddressData: {
                name: null,
                businessName: null,
                addressOne: null,
                addressTwo: null,
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            //billing data
            billingAddressData: {
                name: null,
                businessName: null,
                address: null,
                mobile: null,
                email: null,
                postCode: null,
                city: null,
                stateCode: null
            },
            shippingCountry: "",
            billingCountry: "",
            sameAsBillingAddress: false,
            paymentMethod: "Cash on delivery",
            remarks: "",
            shippingType: "",
            serviceTypeList: [],
            pickUpTypeList: [],
            serviceType: "",
            packageType: "",
            pickUpType: "",
            totalWeight: 0,
            shippingCost: 0,
            orderConfirmed: false,
            showCard: false,
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: ""
            },
            expireDate: "",
            orderId: "",
            disableShipping: true,

            same: false

        }
    },
    async created() {
        this.scrollToTop();
        $('#shipping_methods input').attr('disabled', true);
        this.products = await this.state.cartValue.map(el => {
            let variants = [];
            // if (el.data.choosedColor != "N/A") {
            //     variants.push(el.data.choosedColor);
            // }
            // if (el.data.choosedSize != "N/A") {
            //     variants.push(el.data.choosedSize);
            // }
            return { 'productId': el.data.id, 'quantity': el.quantity, "variants": variants };
        });


    },

    async mounted() {
        await this.getShippingBillingInformation();
        this.checkSB();

        this.calculateWeight();


        //await this.checkShipping();

        this.loadCSS();
    },
    methods: {
        loadCSS: function () {
            var styles = [
                '/core/assets/css/checkout.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        getShippingBillingInformation: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.getAddressURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.data.shippingAddress) {
                            this.shippingAddressData = response.data.data.shippingAddress;
                            this.cloneShippingAddress = response.data.data.shippingAddress;
                        } else {
                            this.shippingAddressData = {
                                name: null,
                                businessName: null,
                                addressOne: null,
                                addressTwo: null,
                                mobile: null,
                                email: null,
                                postCode: null,
                                city: null,
                                stateCode: null
                            }
                        }
                        if (response.data.data.billingAddress) {
                            this.billingAddressData = response.data.data.billingAddress;
                        } else {
                            this.billingAddressData = {
                                name: null,
                                businessName: null,
                                address: null,
                                mobile: null,
                                email: null,
                                postCode: null,
                                city: null,
                                stateCode: null
                            }
                        }
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        updateShippingAddress: async function () {
            try {
                let data = {
                    name: this.shippingAddressData.name,
                    businessName: this.shippingAddressData.businessName,
                    addressOne: this.shippingAddressData.addressOne,
                    addressTwo: this.shippingAddressData.addressTwo,
                    mobile: this.shippingAddressData.mobile,
                    email: this.shippingAddressData.email,
                    postCode: this.shippingAddressData.postCode,
                    stateCode: this.shippingAddressData.stateCode,
                    city: this.shippingAddressData.city,
                    country: this.country.name,
                    countryCode: this.country.code,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.shippingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        this.shippingAddressData = response.data.data;
                        this.shippingCountry = "";
                    } else {
                        this.$swal.fire({
                            icon: "info",
                            text: response.data.message,
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        updateBillingAddress: async function () {
            try {
                console.log("Billing Country: ", this.billingCountry.code)
                let data = {
                    name: this.billingAddressData.name,
                    businessName: this.billingAddressData.businessName,
                    address: this.billingAddressData.address,
                    mobile: this.billingAddressData.mobile,
                    email: this.billingAddressData.email,
                    postCode: this.billingAddressData.postCode,
                    stateCode: this.billingAddressData.stateCode,
                    city: this.billingAddressData.city,
                    country: this.country.name,
                    countryCode: this.country.code,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.profile.billingAddressURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }

                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode) {
                        this.billingAddressData = response.data.data;
                        this.billingCountry = "";
                    } else {
                        this.$swal.fire({
                            icon: "info",
                            text: response.data.message,
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        setShippingAddress: async function () {
            // console.log("data:", this.sameAsBillingAddress);
            // if (this.sameAsBillingAddress === true) {

            this.shippingAddressData.name = this.billingAddressData.name;
            this.shippingAddressData.businessName = this.billingAddressData.businessName;
            this.shippingAddressData.addressOne = this.billingAddressData.address;
            this.shippingAddressData.addressTwo = "";
            this.shippingAddressData.mobile = this.billingAddressData.mobile;
            this.shippingAddressData.email = this.billingAddressData.email;
            this.shippingAddressData.city = this.billingAddressData.city;
            this.shippingAddressData.postCode = this.billingAddressData.postCode;
            this.shippingAddressData.stateCode = this.billingAddressData.stateCode;
            this.shippingAddressData.country = this.country.name
            this.shippingAddressData.countryCode = this.country.code
            await this.checkSB()

            // } else if (this.sameAsBillingAddress === false) {

            //     this.shippingAddressData = {
            //         name: null,
            //         businessName: null,
            //         addressOne: null,
            //         addressTwo: null,
            //         mobile: null,
            //         email: null,
            //         postCode: null,
            //         city: null,
            //         stateCode: null
            //     }

            //}
        },
        Validation: function () {
            if (!this.shippingType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Select Shipping Type.",
                });
            } else if (!this.serviceType) {
                this.$swal.fire({
                    icon: "info",
                    text: "Select Shipping Service Type.",
                });
            }
            else {
                return true;
            }
        },
        checkSB: async function () {
            this.disableShipping = true;
            if (!this.billingAddressData.name) {
                return false;
            } else if (!this.billingAddressData.address) {
                return false;
            } else if (!this.billingAddressData.mobile) {
                return false;
            } else if (!this.billingAddressData.city) {
                return false;
            } else if (!this.billingAddressData.postCode) {
                return false;
            } else if (!this.billingAddressData.stateCode) {
                return false;
            } else if (!this.shippingAddressData.name) {
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                return false;
            } else if (!this.shippingAddressData.email) {
                return false;
            } else if (!this.shippingAddressData.mobile) {
                return false;
            } else if (!this.shippingAddressData.city) {
                return false;
            } else if (!this.shippingAddressData.postCode) {
                return false;
            } else if (!this.shippingAddressData.stateCode) {
                return false;
            } else {
                this.disableShipping = false;
                //this.shippingType = 'fedex';
                await this.handleShipping();
            }
        },
        shippingBillingValidation: function () {
            if (!this.billingAddressData.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Name.",
                });
                return false;
            } else if (!this.billingAddressData.address) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Billing Address.",
                });
                return false;
            } else if (!this.billingAddressData.mobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Mobile Number.",
                });
                return false;
            } else if (this.billingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Name.",
                });
                return false;
            } else if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Address.",
                });
                return false;
            } else if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Email.",
                });
                return false;
            } else if (!this.shippingAddressData.mobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Mobile Number.",
                });
                return false;
            } else if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            } else if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping City.",
                });
                return false;
            } else if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping Zip Code.",
                });
                return false;
            } else if (!this.shippingAddressData.stateCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Enter Shipping State.",
                });
                return false;
            } else {
                return true;
            }
        },
        setShippingCost: function (price) {
            this.shippingCost = price;
        },
        checkShipping: async function () {
            this.serviceTypeList = []
            await this.updateShippingAddress();
            // else if (!this.packageType) {
            //     this.$swal.fire({
            //         icon: "info",
            //         text: "Select package type.",
            //     });
            // }
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.state.isLoading = true;
            await this.$axios(config).then((response) => {
                console.log(response);
                this.state.isLoading = false;
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        upsCheckShipping: async function () {
            this.serviceTypeList = []
            await this.updateShippingAddress();
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.state.isLoading = true;
            await this.$axios(config).then((response) => {
                console.log(response);
                this.state.isLoading = false;
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.state.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        handleShipping: function () {
            if (this.shippingBillingValidation()) {
                if (this.shippingType == 'fedex') {
                    this.checkShipping();
                } else if (this.shippingType == 'ups') {
                    this.upsCheckShipping();
                }
            }

        },

        paymentalidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        handlePayment: async function () {
            if (this.paymentMethod == 'Cash on delivery') {
                this.placeOrder();
            } else if (this.paymentMethod == 'Credit/Debit Card') {
                if (this.paymentalidation()) {
                    this.placeOrder();
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment method."
                });
                return;
            }
        },
        placeOrder: async function () {
            try {
                this.state.isLoading = true;
                this.updateBillingAddress();
                this.updateShippingAddress();
                let data = {
                    shippingAddress: JSON.stringify({
                        name: this.shippingAddressData.name, addressOne: this.shippingAddressData.addressOne, addressTwo: this.shippingAddressData.addressTwo, mobile: this.
                            shippingAddressData.mobile, email: this.shippingAddressData.email, postCode: this.shippingAddressData.postCode, stateCode: this.shippingAddressData.stateCode, city: this.shippingAddressData.city, country: this.shippingCountry.name ? this.shippingCountry.name : this.shippingAddressData.country, countryCode: this.shippingCountry.code ? this.shippingCountry.code : this.shippingAddressData.countryCode
                    }),
                    billingAddress: JSON.stringify({
                        name: this.billingAddressData.name, address: this.billingAddressData.address, mobile: this.billingAddressData.mobile, email: this.
                            billingAddressData.email, stateCode: this.billingAddressData.stateCode, postCode: this.billingAddressData.postCode, city: this.billingAddressData.city, country: this.billingCountry ? this.billingCountry : this.billingAddressData.country
                    }),
                    shippingType: this.shippingType,
                    serviceType: this.serviceType,
                    weightValue: this.totalWeight,
                    products: this.products,
                    paymentMethodName: this.paymentMethod,
                    amount: this.state.couponDiscount
                        ? (parseFloat(this.state.totalAmount + this.shippingCost) - this.state.couponDiscount).toFixed(2) : parseFloat(this.state.totalAmount + this.shippingCost).toFixed(2),
                    shippingCost: this.shippingCost,
                    remarks: this.remarks,
                    coupon: this.state.couponId,
                    ccNum: this.paymentInfo.cardNumber,
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode,
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.orderURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.state.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.state.cartValue = [];
                        this.state.cartCounter = 0;
                        localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        this.$router.push('/dashboard');
                    } else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        }).then(result => {
                            console.log(result);
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.errors.responsetext,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });

            } catch (error) {
                this.state.isLoading = false;
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.state.cartValue.length; i++) {
                this.totalWeight = this.totalWeight + (parseFloat(this.state.cartValue[i].data.weight) * this.state.cartValue[i].quantity);
            }
        },
        confirmedOrder: function () {
            if (this.Validation()) {
                this.orderConfirmed = true;
            }
        },
        // randomCard: function () {
        //     let testCards = [
        //         '4000056655665556',
        //         '5200828282828210',
        //         '371449635398431',
        //         '6011000990139424',
        //         '30569309025904',
        //         '3566002020360505',
        //         '6200000000000005',
        //         '6759649826438453',
        //     ];
        //     let randomNumber = Math.floor(Math.random() * testCards.length);
        //     cardnumber_mask.unmaskedValue = testCards[randomNumber];
        // },

        // handlePayment: async function () {
        //     let newExpDate = this.paymentInfo.expireDate.replace('/', '');
        //     let name = this.shippingAddressData.name.split(' ');
        //     let firstName = name[0];
        //     let lastName = name.length > 1 ? name[1] : ''
        //     let data = {
        //         shippingInfo: {
        //             firstName: firstName,
        //             lastName: lastName,
        //             addressOne: this.shippingAddressData.addressOne,
        //             zip: this.shippingAddressData.postCode,
        //             state: this.shippingAddressData.stateCode,
        //             city: this.shippingAddressData.city,
        //         },
        //         billingInfo: {
        //             firstName: firstName,
        //             lastName: lastName,
        //             addressOne: this.billingAddressData.address,
        //             zip: this.billingAddressData.postCode,
        //             state: this.billingAddressData.stateCode,
        //             city: this.billingAddressData.city,
        //         },
        //         amount: this.state.couponDiscount
        //             ? (
        //                 parseFloat(this.state.totalAmount + this.shippingCost) -
        //                 state.couponDiscount
        //             ).toFixed(2)
        //             : parseFloat(this.state.totalAmount + this.shippingCost).toFixed(2),
        //         ccNum: this.paymentInfo.cardNumber,
        //         ccExp: newExpDate,
        //         cvv: this.paymentInfo.securityCode,
        //         invoiceID: this.orderId
        //     }
        //     let config = {
        //         method: "POST",
        //         url: this.$serverURL + this.$api.payment.paymentURL,
        //         data: data,
        //         headers: {
        //             "Authorization": authHeader()
        //         }
        //     };
        //     this.state.isLoading = true;
        //     await this.$axios(config).then((response) => {
        //         this.state.isLoading = false;
        //         console.log(response);
        //         if (response.data.statusCode == 200) {
        //             // this.state.cartValue = [];
        //             // this.state.cartCounter = 0;
        //             // localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
        //             // this.$swal.fire({
        //             //     icon: "success",
        //             //     text: response.data.message,
        //             // });
        //             // this.$router.push('/dashboard');
        //         }
        //     }).catch(error => {
        //         this.state.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: error.response.data.message
        //         });
        //     });
        // }
    },
    watch: {
        sameAsBillingAddress: function () {
            if (this.sameAsBillingAddress == true) {
                this.setShippingAddress();
            } else if (this.sameAsBillingAddress == false) {
                this.shippingAddressData = {
                    name: null,
                    addressOne: null,
                    addressTwo: null,
                    mobile: null,
                    email: null,
                    postCode: null,
                    city: null,
                    country: null
                }
            }
        },

        paymentMethod: function () {
            if (this.paymentMethod == 'Credit/Debit Card') {
                this.showCard = true;
            } else {
                this.showCard = false;
            }
        },

        shippingType: function () {
            this.orderConfirmed = false;
            this.serviceType = '';
        },

        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        }
    }
}