<template>
    <main class="bg_gray">
        <div class="popup_wrapper">
            <div class="popup_content newsletter">
                <span class="popup_close" id="close_newsletter" @click="setIsNewsLetter">Close</span>
                <div class="row g-0">
                    <div class="col-md-5 d-none d-md-flex align-items-center justify-content-center">
                        <figure><img :src="imagePath + state.newsletterCMS.image" alt=""></figure>
                    </div>
                    <div class="col-md-7">
                        <div class="content">
                            <div class="wrapper">
                                <img :src="imagePath + state.newsletterCMS.companyLogo" alt="" width="100" height="35">
                                <h3>{{state.newsletterCMS.title}}</h3>
                                <p>{{state.newsletterCMS.subTitle}}</p>
                                <form action="#">
                                    <div class="form-group">
                                        <input type="email" required v-model="email" class="form-control" :placeholder="state.newsletterCMS.enterEmailPlaceholder">
                                    </div>

                                    <button type="button" @click="addSubscription" class="btn_1 mt-2 mb-4">Subscribe</button>
                                    <div class="form-group">
                                        <label class="container_check d-inline">{{state.newsletterCMS.dontShowMsg}}
                                            <input type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row -->
            </div>
        </div>
    </main>
</template>
<script src="../js/news_letter.js"></script>