<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <!-- /top_banner -->
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">My Account</a></li>
            <li>Profile</li>
          </ul>
        </div>
        <h1>Profile</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar />
        <!-- /col -->
        <div class="col-lg-9">
          <div class="row small-gutters">
            <div class="maan-personal-information">
              <div class="maan-info-header">
                <h4>Personal Information</h4>
                <a href="#" class="btn_1" @click="editPersonalInfo()">Edit</a>
              </div>
              <div class="maan-user-details">
                <div class="card">
                  <div class="card-body">
                    <form
                      id="personalInfo"
                      @submit.prevent="updatePersonalInfo"
                    >
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">First Name</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            v-model="firstName"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Last Name</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            v-model="lastName"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Business Name</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            v-model="businessName"
                            class="form-control"
                            placeholder="Business Name"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Street Address</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            class="form-control"
                            v-model="address"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Gender</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <select v-model="gender" class="form-control">
                            <option style="display: none" selected value="">
                              {{ gender == "" ? "select gender" : gender }}
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Date of birth</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="date"
                            v-model="dob"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">City</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            class="form-control"
                            v-model="city"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Zip Code</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            class="form-control"
                            v-model="postalCode"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">State</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="text"
                            class="form-control"
                            v-model="stateCode"
                            placeholder="State"
                          />
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Phone</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="tel"
                            v-model="mobile"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <div class="row mb-3" hidden>
                        <div class="col-sm-3">
                          <h6 class="mb-0">Country</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <div class="input-group">
                            <select
                              name="country"
                              v-model="country"
                              class="form-control"
                            >
                              <option
                                v-for="(data, index) in countriesList"
                                :key="index"
                                :value="{
                                  name: data.countryNameEn,
                                  code: data.countryCode,
                                }"
                              >
                                {{ data.countryNameEn }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="!isButtonHidden">
                        <div class="col-sm-3">
                          <h6 class="mb-0">Image</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="file"
                            class="form-control mb-4"
                            name="image"
                            ref="updateImage"
                            @change="uploadProfileImage($event)"
                          />
                          <img
                            v-if="previewImage"
                            :src="previewImage"
                            height="70"
                            width="120"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-9 text-secondary">
                          <input
                            type="submit"
                            v-if="!isButtonHidden"
                            class="btn btn-primary px-4 btn_1"
                            value="Save Changes"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="maan-user-address">
                <div class="card">
                  <div class="maan-info-header">
                    <h4>Address</h4>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="maan-address">
                        <div class="card-body">
                          <h5>
                            Shipping Address:
                            <a href="#" class="" @click="editShippingAddress()"
                              ><i class="ti-pencil-alt"></i
                            ></a>
                          </h5>
                          <form
                            id="shippingAddress"
                            @submit.prevent="saveShippingAddress"
                          >
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Shipping Name</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.name"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Business Name</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.businessName"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Street address One</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.addressOne"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Street address Two</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.addressTwo"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">City</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.city"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Zip Code</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.postCode"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">State</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="shippingAddressData.stateCode"
                                />
                              </div>
                            </div>

                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Mobile</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="tel"
                                  class="form-control"
                                  v-model="shippingAddressData.mobile"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Email</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="email"
                                  class="form-control"
                                  v-model="shippingAddressData.email"
                                />
                              </div>
                            </div>
                            <div class="row mb-3" hidden>
                              <div class="col-sm-3">
                                <h6 class="mb-0">Country</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <select
                                  name="country"
                                  v-model="country"
                                  class="form-control"
                                >
                                  <option
                                    v-for="(data, index) in countriesList"
                                    :key="index"
                                    :value="{
                                      name: data.countryNameEn,
                                      code: data.countryCode,
                                    }"
                                  >
                                    {{ data.countryNameEn }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  v-if="!isShippingButtonHidden"
                                  type="submit"
                                  class="btn btn-primary px-4 btn_1"
                                  value="Save Changes"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="maan-address">
                        <div class="card-body">
                          <form
                            id="billingAddress"
                            @submit.prevent="saveBillingAddress"
                          >
                            <h5>
                              Billing Address:
                              <a href="#" @click="editBillingAddress()"
                                ><i class="ti-pencil-alt"></i
                              ></a>
                            </h5>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Billing Name</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.name"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Business Name</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.businessName"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Street Address</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.address"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">City</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.city"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Zip Code</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.postCode"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">State</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.stateCode"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Mobile</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="billingAddressData.mobile"
                                />
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col-sm-3">
                                <h6 class="mb-0">Email</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <input
                                  type="email"
                                  class="form-control"
                                  v-model="billingAddressData.email"
                                />
                              </div>
                            </div>
                            <div class="row mb-3" hidden>
                              <div class="col-sm-3">
                                <h6 class="mb-0">Country</h6>
                              </div>
                              <div class="col-sm-9 text-secondary">
                                <select
                                  name="country"
                                  v-model="country"
                                  class="form-control"
                                >
                                  <option
                                    v-for="(data, index) in countriesList"
                                    :key="index"
                                    :value="{
                                      name: data.countryNameEn,
                                      code: data.countryCode,
                                    }"
                                  >
                                    {{ data.countryNameEn }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col-sm-9 text-secondary">
                                <button
                                  v-if="!isBillingButtonHidden"
                                  type="submit"
                                  class="btn btn-primary px-4 btn_1"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <Advertise
    v-if="state.advertiseList.includes('Profile') && state.isAdvertise == true"
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('Profile') && state.isNewsLetter == true
    "
  />
</template>
<style scoped>
@import url(../css/style.css);
</style>
<script src="../js/profile.js"></script>