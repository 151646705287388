<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <h3 data-bs-target="#collapse_1">
            {{ state.footerCMS.menuOneTitle }}
          </h3>
          <div class="collapse dont-collapse-sm links" id="collapse_1">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/about-us')"
                  >About us</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/help')"
                  >Help</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/profile')"
                  >My account</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/blog')"
                  >Blog</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/contact')"
                  >Contacts</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 data-bs-target="#collapse_2">
            {{ state.footerCMS.menuTwoTitle }}
          </h3>
          <div class="collapse dont-collapse-sm links" id="collapse_2">
            <ul>
              <li v-for="(data, index) in state.footerCategories" :key="index">
                <a
                  href="javascript:void(0)"
                  @click="
                    $router.push({
                      name: 'Product',
                      query: { categoryId: data.id },
                    })
                  "
                  >{{ data.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 data-bs-target="#collapse_3">
            {{ state.footerCMS.menuThreeTitle }}
          </h3>
          <div class="collapse dont-collapse-sm contacts" id="collapse_3">
            <ul>
              <li>
                <i :class="state.footerCMS.contactAddressIcon"></i
                >{{ state.footerCMS.contactAddress }}
              </li>
              <li>
                <i :class="state.footerCMS.contactNumberIcon"></i
                >{{ state.footerCMS.contactNumber }}
              </li>
              <li>
                <i :class="state.footerCMS.contactEmailIcon"></i
                ><a href="#0">{{ state.footerCMS.contactEmail }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 data-bs-target="#collapse_4">
            {{ state.footerCMS.menuFourTitle }}
          </h3>
          <div class="collapse dont-collapse-sm" id="collapse_4">
            <div id="newsletter">
              <div class="form-group">
                <input
                  type="email"
                  v-model="email"
                  name="email_newsletter"
                  id="email_newsletter"
                  class="form-control"
                  :placeholder="state.footerCMS.emailPlaceHolder"
                />
                <button
                  type="submit"
                  @click="addSubscription"
                  id="submit-newsletter"
                >
                  <i class="ti-angle-double-right"></i>
                </button>
              </div>
            </div>
            <div class="follow_us">
              <h5>{{ state.footerCMS.followUsText }}</h5>
              <ul v-if="state.socialLinks.length > 0">
                <li v-for="(data, index) in state.socialLinks" :key="index">
                  <a :href="data.link" target="_blank"
                    ><img 
                      :src="imagePath + data.image"
                      :data-src="imagePath + data.image"
                      alt=""
                      class="lazy"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- /row-->
      <hr />
      <div class="row add_bottom_25">
        <div class="col-lg-4">
          <ul v-if="state.footerCMS.length > 0" class="footer-selector clearfix">
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageOne"
                :data-src="imagePath + state.footerCMS.paymentImageOne"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageTwo"
                :data-src="imagePath + state.footerCMS.paymentImageTwo"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageThree"
                :data-src="imagePath + state.footerCMS.paymentImageThree"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
            <li>
              <img
                :src="imagePath + state.footerCMS.paymentImageFour"
                :data-src="imagePath + state.footerCMS.paymentImageFour"
                alt=""
                width="50"
                height="30"
                class="lazy"
              />
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <ul class="additional_links">
            <li>
              <a
                href="javascript:void(0)"
                @click="$router.push('/terms-conditions')"
                >Terms & Condition</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="$router.push('/privacy-policy')"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="$router.push('/return-policy')"
                >Return Policy</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="$router.push('/cacellation-policy')"
                >Cancellation Policy</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row add_bottom_25">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <ul class="additional_links">
            <li>
              <span class="powered-by"
                >Built by
                <a href="https://neutrix.co" target="_blank">Neutrix</a> -
                Powered by
                <a href="https://neutrix.co" target="_blank"
                  >Neutrix Systems</a
                ></span
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </footer>
</template>
<script src="../js/footer.js"></script>