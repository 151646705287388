<template>
    <main>
        <div class="top_banner" v-if="pageCMS">
            <div class="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                <div class="container">
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/')">Home</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/franchise')">Franchise</a>
                            </li>
                        </ul>
                    </div>
                    <h1>{{pageCMS.title}}</h1>
                </div>
            </div>
            <img :src="$imageURL + 'franchise/' + pageCMS.image" class="img-fluid" alt="" />
        </div>
        <!-- /container -->
        <div class="sign-up__content">
            <header class="sign-up__header"  v-if="pageCMS">
                <h1 class="sign-up__title">
                    {{pageCMS.formTitle}}
                </h1>
                <p class="sign-up__descr">
                    {{pageCMS.formSubTitle}}
                </p>
            </header>
            <form class="sign-up__form form">
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="first-name" v-model="firstName" placeholder="First Name *"
                                required="" type="text" /><label class="input__label" for="first-name">First
                                Name</label>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="last-name" placeholder="Last Name *" v-model="lastName"
                                required="" type="text" /><label class="input__label" for="last-name">Last Name</label>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="email" placeholder="Email *" v-model="email" required=""
                                type="text" /><label class="input__label" for="email">Email</label>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="password" placeholder="Phone * " v-model="phone" required=""
                                type="text" /><label class="input__label" for="password">Phone</label>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="confirm-password" placeholder="State *" v-model="stateName"
                                required="" type="text" /><label class="input__label"
                                for="confirm-password">State</label>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="password" placeholder="Zip Code * " v-model="zip"
                                required="" type="text" /><label class="input__label" for="password">Zip Code</label>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row--two">
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <select class="form-control form-select" v-model="country" name="countryName">
                                <option style="display: none;" selected value="">Select Country</option>
                                <option v-for="(data, index) in countriesList" :key="index" :value="data">
                                    {{ data }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="input form__inline-input">
                        <div class="input__container">
                            <input class="input__field" id="confirm-password" placeholder="City *" v-model="city"
                                required="" type="text" /><label class="input__label"
                                for="confirm-password">City</label>
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="input">
                        <div class="input__container">
                            <input class="input__field" id="confirm-password" placeholder="Franchise Name*"
                                v-model="franchiseName" required="" type="text" /><label class="input__label"
                                for="confirm-password">Franchise Name</label>
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="input">
                        <div class="input__container" style="height: 120px;">
                            <textarea v-model="notes" style="height: 115px;background: transparent;bottom-border-width: 2px;border-block-width: 2px;border-color: #434176;" placeholder="Write notes"></textarea>
                        </div>
                    </div>
                </div>

                <div class="form__row">
                    <div class="sample-captcha">
                        <VueClientRecaptcha :value="inputValue" @getCode="getCaptchaCode"
                            @isValid="checkValidCaptcha" />
                        <input type="text" class="input__field mt-2" v-model="inputValue"
                            placeholder="Enter captcha code*" />
                        <p class="text-success mt-2" v-if="isSubmit"> <strong>Captcha Verified! </strong></p>
                        <p class="mt-2" style="color: red;" v-else>Please verify captcha!</p>
                    </div>
                </div>
                <div class="form__row">
                    <div class="component component--primary form__button">
                        <button class="btn btn--regular" type="button" @click="addFranchise" :disabled="!isSubmit" id="sign-up-button" tabindex="0">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>
<style>
    @import url('../css/franchise.css');

    .vue_client_recaptcha {
        display: flex;
        justify-content: left;
        flex-direction: row;
    }

    .vue_client_recaptcha_icon {
        text-align: center;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eee;
    }

    .vue_client_recaptcha_icon:hover {
        background-color: #cccccc;
    }

    .vue_client_recaptcha .captcha_canvas {
        background: #eee;
        padding: 10px 0px;
    }
</style>
<script src="../js/franchise.js"></script>