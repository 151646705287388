<template>
  <main>
    <div class="top_banner">
      <div v-if="pageCMS.length > 0"
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.3)"
      >
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/products')"
                  >Product</a
                >
              </li>
            </ul>
          </div>
          <h1>{{ pageCMS.title }}</h1>
        </div>
      </div>
      <img
        :src="$imageURL + 'product-page-cms/' + pageCMS.image"
        class="img-fluid"
        alt=""
      />
    </div>
    <!-- /top_banner -->
    <div id="stick_here"></div>
    <div class="toolbox elemento_stick">
      <div class="container">
        <ul class="clearfix">
          <li>
            <div class="sort_select">
              <select
                v-model="sortBy"
                @change="selectSortBy"
                name="sort"
                id="sort"
              >
                <option value="" style="display: none" selected>Sort by</option>
                <option value="new">Sort by newness</option>
                <option value="low">Sort by price: low to high</option>
                <option value="high">Sort by price: high to low</option>
              </select>
            </div>
          </li>
          <li>
            <a href="#0" class="open_filters">
              <i class="ti-filter"></i><span>Filters</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- /toolbox -->

    <div class="container margin_30">
      <div class="row">
        <aside class="col-lg-3" id="sidebar_fixed">
          <div class="filter_col">
            <div class="inner_bt">
              <a href="#" class="open_filters"><i class="ti-close"></i></a>
            </div>
            <div class="filter_type version_2">
              <h4>
                <a href="#filter_1" data-bs-toggle="collapse" class="opened"
                  >Categories</a
                >
              </h4>
              <div class="collapse show" id="filter_1">
                <ul>
                  <li v-for="(data, index) in categoriesList" :key="index">
                    <label class="container_check"
                      >{{ data.name }}
                      <input
                        v-model="categoryId"
                        @change="getSubCategories(categoryId)"
                        :value="data.id"
                        type="radio"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
              <!-- /filter_type -->
            </div>
            <div
              class="filter_type version_2"
              v-if="subCategoriesList.length > 0"
            >
              <h4>
                <a href="#filter_1" data-bs-toggle="collapse" class="opened"
                  >Sub Categories</a
                >
              </h4>
              <div class="collapse show" id="filter_1">
                <ul>
                  <li v-for="(data, index) in subCategoriesList" :key="index">
                    <label class="container_check"
                      >{{ data.name }}
                      <input
                        v-model="subCategoryId"
                        @change="getChildCategory(subCategoryId)"
                        :value="data.id"
                        type="radio"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
              <!-- /filter_type -->
            </div>
            <div
              class="filter_type version_2"
              v-if="childCategoryList.length > 0"
            >
              <h4>
                <a href="#filter_1" data-bs-toggle="collapse" class="opened"
                  >Child Categories</a
                >
              </h4>
              <div class="collapse show" id="filter_1">
                <ul>
                  <li v-for="(data, index) in childCategoryList" :key="index">
                    <label class="container_check"
                      >{{ data.name }}
                      <input
                        v-model="childCategoryId"
                        :value="data.id"
                        type="radio"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
              <!-- /filter_type -->
            </div>
            <!-- /filter_type -->

            <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_2" data-bs-toggle="collapse" class="opened"
                  >Color</a
                >
              </h4>
              <div class="collapse show" id="filter_2">
                <ul>
                  <li v-for="(data, index) in colorsList" :key="index">
                    <label class="container_check"
                      >{{ data.colorName }}
                      <input
                        v-model="colors"
                        :value="data.colorName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div> -->

            <!-- /filter_type -->
            <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Brands</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in brandList" :key="index">
                    <label class="container_check"
                      >{{ data.brandName }}
                      <input
                        v-model="brandName"
                        :value="data.brandName"
                        type="radio"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="filter_type version_2">
              <h4>
                <a href="#filter_3" data-bs-toggle="collapse" class="closed"
                  >Size</a
                >
              </h4>
              <div class="collapse" id="filter_3">
                <ul>
                  <li v-for="(data, index) in sizesList" :key="index">
                    <label class="container_check"
                      >{{ data.sizeName }}
                      <input
                        v-model="sizes"
                        :value="data.sizeName"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div> -->

            <!-- /filter_type -->
            <div class="filter_type version_2">
              <h4 class="mb-5">
                <a href="#filter_4" data-bs-toggle="collapse" class="closed"
                  >Price</a
                >
              </h4>
              <div class="collapse mb-3" id="filter_4">
                <Slider
                  class="mb-4"
                  v-model="priceValue"
                  :min="0"
                  :max="10000"
                />
                <div class="row">
                  <div class="col-6">
                    <label for="">Min Price:</label>
                    <input
                      style="height: 10px"
                      type="number"
                      v-model="priceValue[0]"
                      min="0"
                      max="10000"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="col-6">
                    <label for="">Max Price:</label>
                    <input
                      style="height: 10px"
                      type="number"
                      v-model="priceValue[1]"
                      min="0"
                      max="10000"
                      class="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- /filter_type -->
            <div class="buttons">
              <a href="javascript:void(0)" @click="filterProduct" class="btn_1"
                >Filter</a
              >
              <a
                href="javascript:void(0)"
                @click="resetFilter"
                class="btn_1 gray"
                >Reset</a
              >
            </div>
          </div>
        </aside>
        <!-- /col -->
        <div class="col-lg-9">
          <div class="row small-gutters" v-if="productList.length > 0">
            <div
              class="col-6 col-md-4"
              v-for="(data, index) in productList"
              :key="index"
            >
              <div class="grid_item">
                <span class="ribbon off" v-if="data.discountTitle">{{
                  data.discountTitle
                }}</span>
                <figure>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/product-details/' + data.id)"
                  >
                    <img
                      class="img-fluid lazy"
                      :src="imagePath + data.featuredImage"
                      :data-src="imagePath + data.featuredImage"
                      alt=""
                    />
                  </a>
                </figure>
                <span>{{ data.brandName }}</span>
                <br />
                <a
                  href="javascript:void(0)"
                  style="color: black"
                  @click="$router.push('/product-details/' + data.id)"
                >
                  <span>{{ data.name }}</span>
                </a>
                <div class="price_box">
                  <span class="new_price"
                    >${{
                      data.discountedPrice ? data.discountedPrice : data.price
                    }}</span
                  >
                  <span class="old_price" v-if="data.discountedPrice"
                    >${{ data.price }}</span
                  >
                </div>
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="tooltip-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Add to favorites"
                      @click="processWishListData(data, 1)"
                      ><i class="ti-heart"></i><span>Add to favorites</span></a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      @click="processCartData(data, 1)"
                      class="tooltip-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Add to cart"
                      ><i class="ti-shopping-cart"></i
                      ><span>Add to cart</span></a
                    >
                  </li>
                </ul>
              </div>
              <!-- /grid_item -->
            </div>
          </div>
          <div class="row small-gutters" v-else>
            <p class="text-center">No Data Found.</p>
          </div>
          <nav
            class="page-navigation justify-content-center d-flex"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="productList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <Advertise
    v-if="state.advertiseList.includes('Products') && state.isAdvertise == true"
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('Products') && state.isNewsLetter == true
    "
  />
</template>
<style scoped>
@import url("../../../assets/pagination.css");
</style>
<script src="../js/product.js"></script>