import siderbar from '../../Dashboard/template/SideBar';
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
export default {
    components: {
        siderbar,
        NewsLetter,
        Advertise,
    },
    data() {
        return {
            state,
            orderId: '',
            url: this.$serverURL + this.$api.trackOrder.trackOrderCMS,
            pageCMS: [],
            imagePath: this.$imageURL + 'track-order/'
        }
    },
    async mounted() {
        await this.getCmsData();
        this.loadCSS();
    },
    methods: {
        loadCSS: function () {
            var styles = [
                '/core/assets/css/error_track.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        getCmsData: async function () {
            let config = {
                method: "GET",
                url: this.url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                }
            }).catch(error => {
                console.log(error);
            });
        },
    }
}