import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            imagePath: this.$imageURL + 'header/',
            subCategoriesList: [],
            lastId: "",
            searchText: '',
            showSubSection: false,
        }
    },
    async created() {
        await this.getCategories();
    },
    methods: {
        getSubCategories: async function (id) {
            if (this.lastId != id) {
                this.lastId = id;
                this.subCategoriesList = [];
                try {
                    let config = {
                        method: "GET",
                        url: this.$serverURL + this.$api.product.productSubCategoryByParentId + '?parentCategoryId=' + id,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    };
                    await this.$axios(config).then(async (response) => {
                        if (response.data.statusCode == 200) {
                            this.subCategoriesList = await response.data.data;
                        }

                    }).catch(error => {
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
}