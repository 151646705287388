import { state } from './globalVariables';

export default {
    data() {
        return {
            state,
        }
    },
    methods: {
        addToCart: function (data, quantity) {
            let duplicate = false;
            this.state.cartValue.forEach((el, index) => {
                if (el.data.id == data.id) {
                    // if (el.data.choosedColor == data.choosedColor) {
                    //     if (el.data.choosedSize == data.choosedSize) {
                    this.state.cartValue[index].quantity = this.state.cartValue[index].quantity + quantity;
                    this.state.cartCounter = this.state.cartCounter + quantity;
                    duplicate = true;
                    //     }
                    // }
                }
            });
            if (duplicate == true) {
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }

            if (!duplicate) {
                this.state.cartValue.push({ data: data, quantity: quantity });
                this.state.cartCounter++;
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    text: "Product added to cart successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                    }
                });
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
                this.calculateTotalAmount();
            }
        },

        calculateTotalAmount: function () {
            this.state.totalAmount = 0;
            if (this.state.cartValue) {
                this.state.cartValue.forEach((el) => {
                    this.state.totalAmount = this.state.totalAmount + ((el.data.discountedPrice ? el.data.discountedPrice : el.data.price) *
                        el.quantity);
                });
                return this.state.totalAmount;
            } else {
                return this.state.totalAmount;
            }
        },

        increaseProductQuantity: function (index) {
            this.state.cartValue[index].quantity++;
            this.state.cartCounter++;
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

        decreaseProductQuantity: function (index) {
            (this.state.cartValue[index].quantity == 1) ? (this.state.cartValue[index].quantity = 1) : (this.state.cartValue[index].quantity--, this.state.cartCounter--);
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

        removeFromCart(index) {
            this.state.cartCounter = this.state.cartCounter - this.state.cartValue[index].quantity;
            this.state.cartValue.splice(index, 1);
            localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            this.calculateTotalAmount();
        },

    }
}