// import { apply } from 'core-js/fn/reflect';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import GAuth from 'vue3-google-oauth2'
import axios from 'axios';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import mixin from './mixin'
import cartMixin from './cartMixin';
import wishListMixin from './wishListMixin';
import Loader from "./components/loader/template/Loader";
import { VueReCaptcha } from 'vue-recaptcha-v3';
const app = createApp(App);
const server = require('../config/server.json');
app.component('Loader', Loader);
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$server = server;
app.config.globalProperties.$hostURL = server.server.HOST;
app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
app.config.globalProperties.$imageURL = server.server.IMAGE_URL;
app.config.globalProperties.$advertiseList = [];
app.config.globalProperties.$newsletterList = [];
app.config.globalProperties.$cartList = "cartList";
app.config.globalProperties.$wishList = "wishList";
app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('DD MMM. YYYY');
    },
    formatBlogDate(date) {
        return moment(String(date)).format('DD/MM/yyyy');
    },
    formatReviewDate(date) {
        return moment(date).fromNow();
    }
};
const googleKey = server.google.logInKey;
const captchaKey = server.google.recaptchaKeyv3;
app.config.globalProperties.$googleKey = googleKey;
const clientID = googleKey + '.apps.googleusercontent.com';
const gAuthOptions = { clientId: clientID, scope: 'profile email', prompt: 'consent', fetch_basic_profile: true };
app.use(VueSweetalert2);
app.mixin(mixin);
app.mixin(cartMixin);
app.mixin(wishListMixin);
app.use(GAuth, gAuthOptions);
app.use(VueReCaptcha, { siteKey: captchaKey })
app.use(router).mount('#app')
