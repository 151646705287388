<template>
  <main class="bg_gray">
    <div class="container margin_30" style="min-height:35rem;">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Category</a></li>
            <li>Page active</li>
          </ul>
        </div>
        <h1>Cart page</h1>
      </div>
      <!-- /page_header -->
      <table class="table table-striped cart-list">
        <thead>
          <tr>
            <th>Product</th>
            <th>Price</th>
            <!-- <th>Color</th>
            <th>Size</th> -->
            <th>Quantity</th>
            <th>Subtotal</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="state.cartValue.length > 0">
          <tr v-for="(data, index) in state.cartValue" :key="index">
            <td>
              <div class="thumb_cart">
                <img
                  v-if="data.data.featuredImage"
                  :src="$imageURL + 'productImage/' + data.data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  class="lazy"
                  alt="Image"
                />
                <img
                  v-else
                  src="core/assets/img/products/product_placeholder_square_small.jpg"
                  data-src="core/assets/img/products/shoes/1.jpg"
                  class="lazy"
                  alt="Image"
                />
              </div>
              <span class="item_cart">{{ data.data.name }}</span>
            </td>
            <td>
              <strong
                >${{
                  data.data.discountedPrice
                    ? data.data.discountedPrice
                    : data.data.price
                }}</strong
              >
            </td>
            <!-- <td>{{ data.data.choosedColor }}</td>
            <td>{{ data.data.choosedSize }}</td> -->
            <td>
              <div class="numbers-row">
                <input
                  type="text"
                  :value="data.quantity"
                  id="quantity_1"
                  class="qty2"
                  name="quantity_1"
                />
                <div
                  class="inc button_inc"
                  @click="increaseProductQuantity(index)"
                >
                  +
                </div>
                <div
                  class="dec button_inc"
                  @click="decreaseProductQuantity(index)"
                >
                  -
                </div>
              </div>
            </td>
            <td>
              <strong
                >${{
                  data.data.discountedPrice
                    ? data.data.discountedPrice * data.quantity
                    : data.data.price * data.quantity
                }}</strong
              >
            </td>
            <td class="options">
              <a href="#"
                ><i class="ti-trash" @click="removeFromCart(index)"></i
              ></a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="1"></td>
            <td colspan="6">Cart is empty</td>
          </tr>
        </tbody>
      </table>

      <div class="row add_top_30 flex-sm-row-reverse cart_actions"  v-if="state.cartValue.length > 0">
        <div class="col-sm-4 text-end"></div>
        <div class="col-sm-8">
          <div class="apply-coupon">
            <div class="form-group">
              <div class="row g-2">
                <div class="col-md-6">
                  <input
                    type="text"
                    name="coupon-code"
                    v-model="couponCode"
                    placeholder="Promo code"
                    class="form-control"
                  />
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn_1 outline"
                    @click="applyCoupon()"
                  >
                    Apply Coupon
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /cart_actions -->
    </div>
    <!-- /container -->

    <div class="box_cart" v-if="state.cartValue.length > 0">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-xl-4 col-lg-4 col-md-6">
            <ul>
              <li><span>Subtotal</span> ${{ state.totalAmount }}</li>
              <li v-if="state.couponDiscount"><span>Coupon discount</span> - ${{ state.couponDiscount }}</li>
              <li><span>Total</span> ${{ state.couponDiscount ? (state.totalAmount-state.couponDiscount) : state.totalAmount }}</li>
            </ul>
            <a
              href="javascript:void(0)"
              @click="$router.push('/checkout')"
              class="btn_1 full-width cart"
              >Proceed to Checkout</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- /box_cart -->
  </main>
  <Advertise v-if="state.advertiseList.includes('Cart') && state.isAdvertise==true"/>
  <NewsLetter v-if="state.newsletterList.includes('Cart') && state.isNewsLetter==true"/>
</template>
<script src="../js/cart.js"></script>