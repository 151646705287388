<template>
<main class="bg_gray">
    <div class="container margin_30">
        <!-- /top_banner -->
      <div class="page_header">
          <div class="breadcrumbs">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">My Account</a></li>
              <li>Quick Tracking Order </li>
            </ul>
        </div>
        <h1>Quick Tracking Order</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar/>
        <!-- /col -->
        <div class="col-lg-9">
          <div id="track_order">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-xl-7 col-lg-9">
                        <img :src="imagePath + pageCMS.image" alt="" class="img-fluid add_bottom_15" width="200" height="177">
                        <p>{{pageCMS.title}}</p>
                        <form>
                            <div class="search_bar">
                                <input type="text" v-model="orderId" class="form-control" :placeholder="pageCMS.placeholder">
                                <input type="submit" @click="$router.push('/order-details/' + orderId)" :value="pageCMS.buttonText">
                            </div>
                        </form>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
         </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <Advertise v-if="state.advertiseList.includes('Track Order') && state.isAdvertise==true"/>
  <NewsLetter v-if="state.newsletterList.includes('Track Order') && state.isNewsLetter==true"/>
  </template>
<script src="../js/track_order.js"></script>