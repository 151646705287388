import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            email: '',
            url: this.$serverURL + this.$api.subscribe.subscriberAPI,
            imagePath: this.$imageURL + 'newsletter/'
        }
    },
    created() {
        sessionStorage.setItem('isNewsLetter', false);
    },
    mounted() {
        this.loadJSCSS();
    },
    methods: {
        setIsNewsLetter() {
            this.state.isNewsLetter = false;
        },
        addSubscription: async function () {
            if (this.email) {
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.url,
                    data: data
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {

                        this.$swal.fire({
                            toast: true,
                            position: 'center',
                            text: "Subscription Successfull",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        document.getElementById("close_newsletter").click();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: " Enter email address!"
                });
            }

        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/home_1.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/carousel-home.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
    }
}