<template>
  <main class="bg_gray">
    <div class="container margin_60_35 add_bottom_30">
      <div class="main_title">
        <h2>{{ cms.pageTitle }}</h2>
        <p>{{ cms.pageSubTitle }}</p>
      </div>
      <div
        class="row justify-content-center align-items-center"
        v-for="(data, index) in content"
        :key="index"
      >
        <div
          class="col-lg-5 pl-lg-5 text-center d-none d-lg-block"
          v-if="data.imagePosition == 'Left'"
        >
          <img
            :src="imageUrl + data.image"
            alt=""
            class="img-fluid"
            width="350"
            height="268"
          />
        </div>
        <div class="col-lg-5">
          <div class="box_about">
            <h2>{{ data.title }}</h2>
            <p class="lead">
              {{ data.subTitle }}
            </p>
            <p v-html="data.description"></p>
            <img
              v-if="
                data.imagePosition == 'Right' && index != content.length - 1
              "
              src="/core/assets/img/arrow_about.png"
              alt=""
              class="arrow_1"
            />
            <img
              v-if="data.imagePosition == 'Left' && index != content.length - 1"
              src="/core/assets/img/arrow_about.png"
              alt=""
              class="arrow_2"
            />
          </div>
        </div>
        <div
          class="col-lg-5 pl-lg-5 text-center d-none d-lg-block"
          v-if="data.imagePosition == 'Right'"
        >
          <img
            :src="imageUrl + data.image"
            alt=""
            class="img-fluid"
            width="350"
            height="268"
          />
        </div>
      </div>
    </div>
    <!-- /container -->

    <div class="bg_white">
      <div class="container margin_60_35">
        <div class="main_title">
          <h2>{{ cms.sectionOneTitle }}</h2>
          <p>{{ cms.sectionOneSubTitle }}</p>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(data, index) in service"
            :key="index"
          >
            <div class="box_feat">
              <i :class="data.iconClass"></i>
              <h3>{{ data.title }}</h3>
              <p v-html="data.description"></p>
            </div>
          </div>
        </div>
        <!--/row-->
      </div>
    </div>
    <!-- /bg_white -->

    <div class="container margin_60">
      <div class="main_title">
        <h2>{{ cms.sectionTwoTitle }}</h2>
        <p>{{ cms.sectionTwoSubTitle }}</p>
      </div>
      <div class="owl-carousel owl-theme carousel_centered">
        <div class="item" v-for="(data, index) in teamMembers" :key="index">
          <a href="javascript:void(0)">
            <div class="title">
              <h4>
                {{ data.name }}<em>{{ data.designation }}</em>
              </h4>
            </div>
            <img :src="imageUrl + data.image" alt="" />
          </a>
        </div>
      </div>
      <!-- /carousel -->
    </div>
    <!-- /container -->
  </main>
  <Advertise v-if="state.advertiseList.includes('About') && state.isAdvertise==true"/>
  <NewsLetter v-if="state.newsletterList.includes('About') && state.isNewsLetter==true"/>
  <!-- <main class="bg_gray" v-else>
    <div id="error_page">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-xl-7 col-lg-9">
            <p>Server Error Occurd!</p>
            <img
              src="/core/assets/img/505-error.png"
              alt=""
              class="img-fluid"
              width="480"
            />
          </div>
        </div>
      </div>
    </div>
  </main> -->
</template>
<script src="../js/about-us.js"></script>