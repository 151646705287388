import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from "../../../auth";
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            productDetails: [],
            gallery: [],
            reviewListData: [],
            relatedProduct: [],
            imagePath: this.$imageURL + 'productImage/',
            isActive: [],
            colorName: '',
            sizeName: '',
            quantity: 1,
            colorList: '',
            sizeList: '',
            pageCMS: {},
            setURL: this.$serverURL + this.$api.product.productDetails + this.$route.params.id,
            productId: this.$route.params.id,
            size: "",
            productRating: 0,
            notVoted: 5,
            voted: 0,
            totalWeight: 0
        }
    },
    created() {
        this.state.isLoading = true;
        this.scrollToTop();
    },
    async mounted() {
        this.loadCSS();
        await this.getProductDetails();
    },
    methods: {

        selectColor: function (index) {
            this.isActive = [];
            this.isActive[index] = !this.isActive[index];
            if (this.isActive[index]) {
                this.colorName = this.productDetails.colors[index].colorName;
            }
        },
        getProductDetails: async function () {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.productDetails = response.data.detailsData[0];
                    this.pageCMS = response.data.productDetailsPageCmsData[0];
                    this.reviewListData = response.data.reviewListData;
                    this.relatedProduct = response.data.relatedProductData;
                    this.gallery = response.data.detailsData[0].photos;
                    console.log("+++ Gallery : ", this.gallery);
                    console.log("+++ Gallery : ", response.data.detailsData[0].photos);
                    // for (let key in Object.keys(response.data.detailsData[0].colors)) {
                    //     this.colorList = this.colorList + response.data.detailsData[0].colors[key].colorName;
                    //     if (key != response.data.detailsData[0].colors.length - 1) {
                    //         this.colorList = this.colorList + ', ';
                    //     }
                    // }
                    // for (let key in Object.keys(response.data.detailsData[0].sizes)) {
                    //     this.sizeList = this.sizeList + response.data.detailsData[0].sizes[key].sizeName;
                    //     if (key != response.data.detailsData[0].sizes.length - 1) {
                    //         this.sizeList = this.sizeList + ', ';
                    //     }
                    // }
                    if (this.reviewListData.length > 0) {
                        this.voted = 0
                        let sum = 0;
                        for (let i = 0; i < this.reviewListData.length; i++) {
                            sum = sum + parseInt(this.reviewListData[i].rating);
                        }
                        this.productRating = (sum / this.reviewListData.length).toFixed(1);
                        this.voted = Math.floor(sum / this.reviewListData.length);
                        this.notVoted = this.notVoted - this.voted;
                    } else {
                        this.voted = 0
                    }
                    this.state.isLoading = false;
                }
                this.state.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        leaveReview: function () {
            if (localStorage.getItem('token')) {
                this.$router.push({
                    name: 'ProductReview',
                    params: { 'productId': this.productId },
                    query: { 'productName': this.productDetails.name }
                });
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please login to leave a review!"
                });
            }

        },
        loadCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/product_page.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
                "/core/assets/js/carousel-home.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            this.state.isLoading = false;
        },
        deductQuantity() {
            (this.quantity > 1) ? (this.quantity = this.quantity - 1) : (this.quantity = 1);
        },
        increaseQuantity() {
            this.quantity = this.quantity + 1;
        },
        processWishListData(data) {
            // data.choosedColor = this.colorName ? this.colorName : "N/A";
            // data.choosedSize = this.size ? this.size : "N/A";
            this.addToWishList(data, this.quantity);
            this.$router.push('/products');
        },
        processCartData(data) {
            // data.choosedColor = this.colorName ? this.colorName : "N/A";
            // data.choosedSize = this.size ? this.size : "N/A";
            this.addToCart(data, this.quantity);
            this.$router.push('/products')
        },

    }
}