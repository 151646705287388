
import { state } from '@/globalVariables';
export default {
    data() {
        return {
            state,
            image: localStorage.getItem('image'),
            hasImageFile:false,
            updateImage: "",
            previewImage:"",
            name: localStorage.getItem("name")
        }
    },
    methods: {
    },
    mounted () {
        var styles = [
            '/core/assets/css/listing.css'
        ]
        styles.forEach(style => {
            let tag = document.createElement('link');
            tag.type = "text/css";
            tag.rel = "stylesheet";
            tag.href = style ;
            document.head.appendChild(tag);
        });
        
        var scripts = [
            "/core/assets/js/sticky_sidebar.min.js",
            "/core/assets/js/specific_listing.js",
            "/core/assets/js/main.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        
    }
}