<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Category</a></li>
            <li>Page active</li>
          </ul>
        </div>
        <h1>Sign In or Create an Account</h1>
      </div>
      <!-- /page_header -->
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-md-8">
          <div class="box_account">
            <h3 class="client">Already Client</h3>
            <div class="form_container">
              <div class="row no-gutters">
                <!-- <div class="col-lg-6 pr-lg-1">
                                    <a href="#0" class="social_bt facebook">Login with Facebook</a>
                                </div> -->
                <div class="col-lg-12 pl-lg-1">
                  <a
                    href="javascript:void(0)"
                    @click="googleSignIn"
                    class="social_bt google"
                    >Login with Google</a
                  >
                </div>
              </div>
              <div class="divider"><span>Or</span></div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                />
              </div>
              <div class="form-group input-group" id="show_hide_password">
                <input
                  :type="inputType"
                  v-model="password"
                  class="form-control border-end-0"
                  id="inputPassword"
                  placeholder="Enter Password"
                /><a
                  @click="changeInputType"
                  href="javascript:void(0)"
                  class="input-group-text bg-transparent"
                  ><i
                    :class="
                      iconFlag ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                    "
                  ></i
                ></a>
              </div>
              <div class="clearfix add_bottom_15">
                <div class="checkboxes float-start">
                  <label class="container_check"
                    >Remember me
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="float-end">
                  <a id="forgot" href="javascript:void(0);">Lost Password?</a>
                </div>
              </div>
              <div class="text-center" @click="login">
                <input type="submit" value="Log In" class="btn_1 full-width" />
              </div>
              <div id="forgot_pw">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="resetEmail"
                    class="form-control"
                    name="email_forgot"
                    id="email_forgot"
                    placeholder="Type your email"
                  />
                </div>
                <p>A new password will be sent shortly.</p>
                <div class="text-center" @click="resetPassword">
                  <input type="submit" value="Reset Password" class="btn_1" />
                </div>
              </div>
            </div>
            <!-- /form_container -->
          </div>
          <!-- /box_account -->

          <!-- /row -->
        </div>
        <div class="col-xl-6 col-lg-6 col-md-8">
          <div class="box_account">
            <h3 class="new_client">New Client</h3>
            <small class="float-right pt-2">* Required Fields</small>
            <div class="form_container">
              <div class="private box">
                <div class="row no-gutters">
                  <div class="col-6 pr-1">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="firstName"
                        class="form-control"
                        placeholder="First Name*"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="lastName"
                        class="form-control"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="businessName"
                      class="form-control"
                      placeholder="Business Name"
                    />
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="address"
                        class="form-control"
                        placeholder="Street Address*"
                      />
                    </div>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 pl-1">
                    <div class="form-group">
                      <select
                        class="form-control form-select"
                        v-model="gender"
                        id="gender"
                      >
                        <option style="display: none" value="">
                          Select gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6 pr-1">
                    <div class="form-group">
                      <input
                        type="date"
                        class="form-control"
                        v-model="dob"
                        placeholder="Date Of Birth*"
                      />
                    </div>
                  </div>
                </div>
                <!-- /row -->
                <div class="row no-gutters">
                  <div class="col-12 pr-1">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="city"
                        class="form-control"
                        placeholder="City*"
                      />
                    </div>
                  </div>
                  <div class="col-12 pl-1">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="postCode"
                        class="form-control"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>
                  <div class="col-12 pl-1">
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="stateCode"
                        class="form-control"
                        placeholder="State"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    v-model="customerEmail"
                    name="email"
                    id="email_2"
                    placeholder="Email*"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="mobile"
                    class="form-control"
                    placeholder="Mobile *"
                  />
                </div>

                <div class="form-group input-group" id="show_hide_password">
                  <input
                    :type="passinputType"
                    v-model="customerPassword"
                    class="form-control border-end-0"
                    id="inputPassword"
                    placeholder="Enter Password*"
                  /><a
                    @click="changeInputTypeReg"
                    href="javascript:void(0)"
                    class="input-group-text bg-transparent"
                    ><i
                      :class="
                        passiconFlag
                          ? 'fa-solid fa-eye-slash'
                          : 'fa-solid fa-eye'
                      "
                    ></i
                  ></a>
                </div>
                <div class="form-group input-group" id="show_hide_password">
                  <input
                    :type="coninputType"
                    v-model="confirmPassword"
                    class="form-control border-end-0"
                    id="inputPassword"
                    placeholder="Enter Confirm Password*"
                  /><a
                    @click="changeInputTypeCon"
                    href="javascript:void(0)"
                    class="input-group-text bg-transparent"
                    ><i
                      :class="
                        coniconFlag
                          ? 'fa-solid fa-eye-slash'
                          : 'fa-solid fa-eye'
                      "
                    ></i
                  ></a>
                </div>
                <!-- /row -->
                <div class="row no-gutters">
                  <div class="col-6 pr-1" hidden>
                    <div class="form-group">
                      <label>Country</label>
                      <select
                        class="form-control form-select"
                        v-model="country"
                        name="countryName"
                      >
                        <option
                          v-for="(data, index) in countriesList"
                          :key="index"
                          :value="{
                            name: data.countryNameEn,
                            code: data.countryCode,
                          }"
                        >
                          {{ data.countryNameEn }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- /row -->
              </div>
              <!-- /private -->

              <!-- /company -->
              <hr />
              <div class="form-group">
                <label class="container_check"
                  >Accept
                  <a href="/terms-conditions" target="_blank"
                    >Terms and conditions</a
                  >
                  <input type="checkbox" v-model="terms_condition" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="text-center" @click="register">
                <input
                  type="submit"
                  value="Register"
                  class="btn_1 full-width"
                />
              </div>
            </div>
            <!-- /form_container -->
          </div>
          <!-- /box_account -->
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <Advertise
    v-if="state.advertiseList.includes('Account') && state.isAdvertise == true"
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('Account') && state.isNewsLetter == true
    "
  />
</template>
<script src="../js/account.js"></script>