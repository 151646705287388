<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <!-- /top_banner -->
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">My Account</a></li>
            <li>Dashboard</li>
          </ul>
        </div>
        <h1>Dashboard</h1>
      </div>
      <!-- /toolbox -->
      <div class="row">
        <siderbar />
        <!-- /col -->
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-4">
              <div class="card card-body mb-4 maan-radius">
                <article class="icontext">
                  <span class="icon icon-sm rounded-circle">
                    <img src="/core/assets/img/icons/1.svg" alt="Icon"
                  /></span>
                  <div class="text">
                    <h6 class="mb-1 card-title">Total Order</h6>
                    <span>{{ totalOrders }}</span>
                    <!-- <span class="text-sm"> Shipping fees are not included </span> -->
                  </div>
                </article>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-body mb-4 maan-radius">
                <article class="icontext">
                  <span class="icon icon-sm rounded-circle"
                    ><img
                      src="/core/assets/img/icons/track-blue.svg"
                      alt="Icon"
                  /></span>
                  <div class="text">
                    <h6 class="mb-1 card-title">Order Pending</h6>
                    <span>{{ totalPendingOrders }}</span>
                  </div>
                </article>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card card-body mb-4 maan-radius">
                <article class="icontext">
                  <span class="icon icon-sm rounded-circle"
                    ><img
                      src="/core/assets/img/icons/track-green.svg"
                      alt="Icon"
                  /></span>
                  <div class="text">
                    <h6 class="mb-1 card-title">Order Shipped</h6>
                    <span>{{ totalShippedOrders }}</span>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-body mb-4 maan-radius">
                <article class="icontext">
                  <span class="icon icon-sm rounded-circle"
                    ><img
                      src="/core/assets/img/icons/Confirmed-green.svg"
                      alt="Icon"
                  /></span>
                  <div class="text" style="padding-top: 5px">
                    <h6 class="mb-1 card-title">Order Delivered</h6>
                    <span>{{ totalDeliveredOrders }}</span>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card card-body mb-4 maan-radius">
                <article class="icontext">
                  <span class="icon icon-sm rounded-circle"
                    ><img
                      src="/core/assets/img/icons/order-cancel.svg"
                      alt="Icon"
                  /></span>
                  <div class="text" style="padding-top: 5px">
                    <h6 class="mb-1 card-title">Order Cancelled</h6>
                    <span>{{ totalCancelledOrders }}</span>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <header class="card-header">
              <h4 class="card-title">Latest orders</h4>
            </header>
            <div class="card-body" v-if="orders.length > 0">
              <div class="table-responsive">
                <div class="table-responsive">
                  <table class="table align-middle table-nowrap mb-0">
                    <thead class="table-light">
                      <tr>
                        <th class="align-middle" scope="col">Order ID</th>
                        <th class="align-middle" scope="col">Billing Name</th>
                        <th class="align-middle" scope="col">Date</th>
                        <th class="align-middle" scope="col">Total</th>
                        <th class="align-middle" scope="col">Payment Status</th>
                        <th class="align-middle" scope="col">Payment Method</th>
                        <th class="align-middle" scope="col">View Details</th>
                      </tr>
                    </thead>
                    <tbody style="border-top: 0px">
                      <tr v-for="(data, index) in orders" :key="index">
                        <td>
                          <a
                            href="javascript:void(0)"
                            @click="
                              $router.push('/order-details/' + data.orderId)
                            "
                            class="fw-bold"
                            >#{{ data.orderId }}</a
                          >
                        </td>
                        <td>{{ data.customerName }}</td>
                        <td>{{ $filters.formatDate(data.createdAt) }}</td>
                        <td>${{ data.total }}</td>
                        <td>
                          <span
                            :class="[
                              'badge badge-pill',
                              data.paymentStatus == 'PENDING'
                                ? 'badge-soft-warning'
                                : data.paymentStatus == 'Paid'
                                ? 'badge-soft-success'
                                : 'badge-soft-danger',
                            ]"
                            >{{ data.paymentStatus.toUpperCase() }}</span
                          >
                        </td>
                        <td>
                          <i
                            class="
                              material-icons
                              md-payment
                              font-xxl
                              text-muted
                              mr-5
                            "
                          ></i>
                          {{ data.paymentMethodName }}
                        </td>
                        <td>
                          <router-link
                            :to="'/order-details/' + data.orderId"
                            class="btn btn-dash-xs"
                          >
                            View details</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- table-responsive end// -->
            </div>
            <div class="card-body" v-else>
              <p class="text-center">No data found.</p>
            </div>
          </div>
        </div>
        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
</template>
<style scoped>
@import url(../css/style.css);
</style>
<script src="../js/dashboard.js"></script>