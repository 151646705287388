<template>
    <main>
        <div class="container margin_60_35">

            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="write_review">
                        <h1>Write a review for {{productName}}</h1>
                        <div class="rating_submit">
                            <div class="form-group">
                                <label class="d-block">Overall rating</label>
                                <span class="rating mb-0">
                                    <input v-model="rating" type="radio" class="rating-input" id="5_star" name="rating-input"
                                        value="5"><label for="5_star" class="rating-star"></label>
                                    <input v-model="rating" type="radio" class="rating-input" id="4_star" name="rating-input"
                                        value="4"><label for="4_star" class="rating-star"></label>
                                    <input v-model="rating" type="radio" class="rating-input" id="3_star" name="rating-input"
                                        value="3"><label for="3_star" class="rating-star"></label>
                                    <input v-model="rating" type="radio" class="rating-input" id="2_star" name="rating-input"
                                        value="2"><label for="2_star" class="rating-star"></label>
                                    <input v-model="rating" type="radio" class="rating-input" id="1_star" name="rating-input"
                                        value="1"><label for="1_star" class="rating-star"></label>
                                </span>
                            </div>
                        </div>
                        <!-- /rating_submit -->
                        <div class="form-group">
                            <label>Title of your review</label>
                            <input v-model="title" class="form-control" type="text"
                                placeholder="If you could say it in one sentence, what would you say?">
                        </div>
                        <div class="form-group">
                            <label>Your review</label>
                            <textarea v-model="review" class="form-control" style="height: 180px;"
                                placeholder="Write your review to help others learn about this online business"></textarea>
                        </div>
                        <div class="form-group">
                            <label>Add your photo (optional)</label>
                            <div class="fileupload"><input type="file" ref="addImage" @change="uploadImage" name="fileupload" accept="image/*"></div>
                        </div>
                        <a href="javascript:void(0)" @click="addReview" class="btn_1">Submit review</a>
                    </div>
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
    </main>
</template>
<script src="../js/product-review.js"></script>