<template>
	<main class="bg_gray">
		<div class="container margin_30">
			<div class="page_header">
				<div class="breadcrumbs">
					<ul>
						<li><a href="javascript:void(0)">Home</a></li>
						<li><a href="javascript:void(0)">Blog</a></li>
						<li>Page active</li>
					</ul>
				</div>
				<h1>{{blogPageCMS.title}}</h1>
			</div>
			<!-- /page_header -->
			<div class="row">
				<div class="col-lg-9">
					<div class="widget search_blog d-block d-sm-block d-md-block d-lg-none">
						<div class="form-group">
							<input type="text" name="search" id="search" class="form-control"
								:placeholder="blogPageCMS.searchPlaceholder">
							<button type="submit"><i class="ti-search"></i></button>
						</div>
					</div>
					<!-- /widget -->
					<div class="row" v-if="blogPostList.length > 0">
						<div class="col-md-6" v-for="(data, index) in blogPostList" :key="index">
							<article class="blog">
								<figure>
									<a href="javascript:void(0)" @click="$router.push('/blog-details/' + data.id)"><img
											:src="imagePath + data.photo" alt="">
										<div class="preview"><span>Read more</span></div>
									</a>
								</figure>
								<div class="post_info">
									<small>{{data.categoryName}} - {{$filters.formatDate(data.createdAt)}}</small>
									<h2><a href="javascript:void(0)"
											@click="$router.push('/blog-details/' + data.id)">{{data.title}}</a></h2>
									<p v-html="data.shortDetails"></p>
									<ul>
										<li>
											<div class="thumb"><img :src="imagePath + data.uploaderPhoto" alt=""></div>
											{{data.postedBy}}
										</li>
										<li><i class="ti-comment"></i>{{data.totalComments}}</li>
									</ul>
								</div>
							</article>
							<!-- /article -->
						</div>

						<!-- /col -->

						<!-- /col -->
					</div>
					<div class="row" v-else>
						<p>No Data Found.</p>
					</div>
					<!-- /row -->

					<nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
						<v-pagination v-if="blogPostList.length > 0" v-model="page" @update:modelValue="updateHandler"
							:pages="totalPage" :range-size="1" active-color="#FF8400"></v-pagination>
					</nav>
					<!-- /pagination -->

				</div>
				<!-- /col -->

				<aside class="col-lg-3">
					<div class="widget search_blog d-none d-sm-none d-md-none d-lg-block">
						<div class="form-group">
							<input type="text" v-model="keyword" name="search" id="search_blog" class="form-control" v-on:keyup.enter="searchKeyword"
								:placeholder="blogPageCMS.searchPlaceholder">
							<button type="submit" @click="searchKeyword"><i class="ti-search"></i></button>
						</div>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Latest Post</h4>
						</div>
						<ul class="comments-list">
							<li v-for="(data, index) in latestBlogs" :key="index">
								<div class="alignleft">
									<a href="javascript:void(0)" @click="$router.push('/blog-details/' + data.id)"><img
											:src="imagePath + data.photo" alt=""></a>
								</div>
								<small>{{data.categoryName}} - {{$filters.formatDate(data.createdAt)}}</small>
								<h3><a href="javascript:void(0)" title=""
										@click="$router.push('/blog-details/' + data.id)">{{data.title}}</a></h3>
							</li>

						</ul>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Categories</h4>
						</div>
						<ul class="cats">
							<li v-for="(data, index) in blogCategoryList" :key="index">
								<a href="javascript:void(0)" @click="searchCategory(data.id)">{{data.categoryName}}
									<span>({{ data.count }})</span></a>
							</li>
						</ul>
					</div>
					<!-- /widget -->
					<div class="widget">
						<div class="widget-title">
							<h4>Popular Tags</h4>
						</div>
						<div class="tags">
							<a class="me-2" href="javascript:void(0)" @click="searchTag(data.tagName)"
								v-for="(data, index) in blogTagList" :key="index">{{data.tagName}}</a>
						</div>
					</div>
					<!-- /widget -->
				</aside>
				<!-- /aside -->
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
	</main>
	<Advertise v-if="state.advertiseList.includes('Blogs') && state.isAdvertise==true"/>
	<NewsLetter v-if="state.newsletterList.includes('Blogs') && state.isNewsLetter==true"/>
</template>
<style scoped>
	@import url("../../../assets/pagination.css");
</style>
<script src="../js/blog.js"></script>