<template>
  <div id="page">
    <Header />
    <Loader :spin="state.isLoading"></Loader>
    <router-view :key="$route.fullPath"></router-view>
    <a class="btn_1 fixedbutton" href="javascript:void(0)" role="button" @click="$router.push(state.headerCMS.buttonLink)"
      >{{state.headerCMS.buttonText}}</a
    >
    <Footer />
  </div>
</template>

<script>
import Header from "./components/layouts/template/Header";
import Footer from "./components/layouts/template/Footer";
import { state } from "@/globalVariables";
import { authHeader } from "./auth";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      state,
      token: localStorage.getItem("token"),
      watchLoginCheck: false,
      date: new Date(),
    };
  },
  async created() {
    var w = window.innerWidth;
    console.log("width",w);
    this.calculateTotalAmount();
    await this.getSiteSettings();

    if (!localStorage.getItem(this.$cartList)) {
      localStorage.setItem(this.$cartList, null);
    }
    if (!localStorage.getItem(this.$wishList)) {
      localStorage.setItem(this.$wishList, null);
    }
    if (
      this.$route.path == "/account" ||
      this.$route.path == "/reset-password"
    ) {
      if (this.token) {
        this.$router.push("/");
      }
    }
  },
  watch: {
    $route() {
      if (
        this.$route.path == "/account" ||
        this.$route.path == "/reset-password"
      ) {
        if (this.token) {
          this.$router.push("/");
        }
      } else {
        if (this.watchLoginCheck == false) {
          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.auth.validateTokenURL,
            headers: {
              Authorization: authHeader(),
            },
          };
          this.$axios(config)
            .then((response) => {
              if (response.status == 201) {
                if (response.data === false) {
                  this.clearLocalVariables();
                  this.watchLoginCheck = true;
                } else {
                  this.watchLoginCheck = false;
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
  methods: {
    getSiteSettings: async function () {
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.site.siteSettings,
        };
        await this.$axios(config)
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.state.adCMS = response.data.data.advertiseCMS
                ? response.data.data.advertiseCMS
                : {};
              this.state.newsletterCMS = response.data.data.newsletterCMS
                ? response.data.data.newsletterCMS
                : {};
              this.state.popUpSettings = response.data.data.popupCMS
                ? response.data.data.popupCMS
                : {};
              this.state.headerCMS = response.data.data.headerCMS
                ? response.data.data.headerCMS
                : {};
              this.state.footerCMS = response.data.data.footerCMS
                ? response.data.data.footerCMS
                : {};
              this.state.paymentMethods = response.data.data.paymentMethod
                ? response.data.data.paymentMethod
                : [];
              this.state.advertiseList = response.data.data.popupCMS?.advertise
                ? JSON.parse(response.data.data.popupCMS.advertise)
                : [];
              this.state.newsletterList = response.data.data.popupCMS
                ?.newsletter
                ? response.data.data.popupCMS.newsletter
                : [];
              this.state.footerCategories = response.data.data.footerCategories
                ? response.data.data.footerCategories
                : [];
              this.state.socialLinks = response.data.data.siteSocialLinksData
                ? response.data.data.siteSocialLinksData
                : [];
              this.state.locationCms = response.data.data.locationCms[0]
                ? response.data.data.locationCms[0]
                : {};
              this.state.hearAboutUsOptions = this.state.locationCms ? this.state.locationCms.hearAboutUsOptions.split(",") : [];
            } else {
              this.$swal.fire({
                icon: "error",
                text: "Failed! Try Again",
              });
              this.$router.push("/account");
            }
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: error.response.data.message,
            });
            this.$router.push("/account");
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.fixedbutton {
  position: fixed;
  bottom: 363px;
  right: -46px;
  z-index: 2;
  transform: rotate(270deg);
}
@media screen and (max-width: 600px) {
  .fixedbutton {
    position: fixed;
    bottom: 48%;
    right: -46px;
    z-index: 2;
    transform: rotate(270deg);
  }
}
</style>
