<template>
  <header class="version_1">
    <div class="layer"></div>
    <div class="main_header">
      <div class="container">
        <div class="row small-gutters">
          <div class="col-xl-2 col-lg-2 d-lg-flex align-items-center">
            <div id="logo">
              <a href="javascript:void(0)" @click="$router.push('/')"
                ><img :src="imagePath + state.headerCMS.logo" alt=""
              /></a>
            </div>
          </div>
          <nav class="col-xl-7 col-lg-8">
            <a class="open_close" href="javascript:void(0);">
              <div class="hamburger hamburger--spin">
                <div class="hamburger-box">
                  <div class="hamburger-inner"></div>
                </div>
              </div>
            </a>
            <!-- Mobile menu button -->
            <div class="main-menu">
              <div id="header_menu" v-if="state.headerCMS?.logo">
                <a href="javascript:void(0)" @click="$router.push('/')"
                  ><img
                    :src="imagePath + state.headerCMS?.logo"
                    alt=""
                    style="width: 200px !important; height: auto !important"
                /></a>
                <a href="#" class="open_close" id="close_in"
                  ><i class="ti-close"></i
                ></a>
              </div>
              <ul>
                <li>
                  <a
                    href="javascript:void(0);"
                    @click="$router.push('/')"
                    class="show-submenu"
                    >Home</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/products')"
                    >Product</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" @click="$router.push('/blog')"
                    >Blog</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/franchise')"
                    >Franchise</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/about-us')"
                    >About US</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/location')"
                    >Our Location</a
                  >
                </li>
              </ul>
            </div>
            <!--/main-menu -->
          </nav>
          <div v-if="state.headerCMS.length > 0"
            class="
              col-xl-3 col-lg-2
              d-lg-flex
              align-items-center
              justify-content-end
              text-end
            "
          >
            <a class="phone_top" :href="'tel:' + state.headerCMS.helpLineNumber"
              ><strong
                ><span>{{ state.headerCMS.helpLineText }}</span
                >{{ state.headerCMS.helpLineNumber }}</strong
              ></a
            >
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /main_header -->

    <div class="main_nav Sticky">
      <div class="container">
        <div class="row small-gutters">
          <div class="col-xl-3 col-lg-3 col-md-3">
            <nav class="categories">
              <ul class="clearfix">
                <li>
                  <span>
                    <a href="#">
                      <span class="hamburger hamburger--spin">
                        <span class="hamburger-box">
                          <span class="hamburger-inner"></span>
                        </span>
                      </span>
                      Categories
                    </a>
                  </span>
                  <div id="menu" v-if="categoriesList.length > 0">
                    <ul>
                      <li v-for="(data, index) in categoriesList" :key="index">
                        <span
                          ><a
                            @mouseover="getSubCategories(data.id)"
                            href="javascript:void(0)"
                            @click="
                              $router.push({
                                name: 'Product',
                                query: { categoryId: data.id },
                              })
                            "
                            >{{ data.name }}</a
                          ></span
                        >
                        <ul v-if="data.hasChild">
                          <li
                            v-for="(data, index) in subCategoriesList"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0)"
                              @click="
                                $router.push({
                                  name: 'Product',
                                  query: { subCategoryId: data.id },
                                })
                              "
                              >{{ data.name }}</a
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-xl-6 col-lg-7 col-md-6 d-none d-md-block">
            <div class="custom-search-input">
              <input
                type="text"
                v-model="searchText"
                v-on:keyup.enter="
                  $router.push({
                    name: 'Product',
                    query: { keyword: searchText },
                  })
                "
                :placeholder="state.headerCMS.searchPlaceholder"
              />
              <button
                type="submit"
                @click="
                  $router.push({
                    name: 'Product',
                    query: { keyword: searchText },
                  })
                "
              >
                <i class="header-icon_search_custom"></i>
              </button>
            </div>
          </div>
          <div class="col-xl-3 col-lg-2 col-md-3">
            <ul class="top_tools">
              <li>
                <div class="dropdown dropdown-cart">
                  <a
                    href="javascript:void(0)"
                    @click="$router.push('/cart')"
                    class="cart_bt"
                    ><strong>{{ state.cartCounter }}</strong></a
                  >
                  <div class="dropdown-menu">
                    <ul v-if="state.cartValue.length > 0">
                      <li v-for="(data, index) in state.cartValue" :key="index">
                        <a href="#">
                          <figure>
                            <img
                              v-if="data.data.featuredImage"
                              :src="
                                $imageURL +
                                'productImage/' +
                                data.data.featuredImage
                              "
                              :data-src="
                                $imageURL +
                                'productImage/' +
                                data.data.featuredImage
                              "
                              alt=""
                              width="50"
                              height="50"
                              class="lazy"
                            />
                            <img
                              v-else
                              src="/core/assets/img/products/product_placeholder_square_small.jpg"
                              data-src="/core/assets/img/products/shoes/thumb/1.jpg"
                              alt=""
                              width="50"
                              height="50"
                              class="lazy"
                            />
                          </figure>
                          <strong
                            ><span
                              >{{ data.data.name }}&nbsp;X&nbsp;
                              {{ data.quantity }}</span
                            >${{
                              data.data.discountedPrice
                                ? data.data.discountedPrice
                                : data.data.price
                            }}</strong
                          >
                        </a>
                        <a
                          href="javascript:void(0)"
                          class="action"
                          @click="removeFromCart(index)"
                          ><i class="ti-trash"></i
                        ></a>
                      </li>
                    </ul>
                    <div class="total_drop" v-if="state.cartValue.length > 0">
                      <div class="clearfix">
                        <strong>Total</strong
                        ><span>${{ state.totalAmount }}</span>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="btn_1 outline"
                        @click="$router.push('/cart')"
                        >View Cart</a
                      ><a
                        href="javascript:void(0)"
                        @click="$router.push('/checkout')"
                        class="btn_1"
                        >Checkout</a
                      >
                    </div>
                    <div class="total_drop" v-else>
                      <center>
                        <strong>Cart is empty</strong>
                      </center>
                    </div>
                  </div>
                </div>
                <!-- /dropdown-cart-->
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="$router.push('/wishlist')"
                  class="wishlist"
                  ><strong class="wishListCount">{{
                    state.wishlistCounter
                  }}</strong></a
                >
              </li>
              <li>
                <div class="dropdown dropdown-access">
                  <a href="javascript:void(0)" class="access_link"
                    ><span>Account</span></a
                  >
                  <div class="dropdown-menu">
                    <a
                      v-if="!state.token"
                      href="javascript:void(0)"
                      @click="$router.push('/account')"
                      class="btn_1"
                      >Sign In or Sign Up</a
                    >
                    <ul>
                      <li v-if="state.token">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/profile')"
                          ><i class="ti-user"></i>My Profile</a
                        >
                      </li>
                      <li v-if="state.token">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/dashboard')"
                          ><i class="ti-package"></i>My Orders</a
                        >
                      </li>
                      <li v-if="state.token">
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/track-order')"
                          ><i class="ti-truck"></i>Track your Order</a
                        >
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          @click="$router.push('/help')"
                          ><i class="ti-help-alt"></i>Help</a
                        >
                      </li>
                      <li v-if="state.token">
                        <a href="javascript:void(0)" @click="logout()"
                          ><i class="fa-solid fa-right-from-bracket"></i
                          >Logout</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- /dropdown-access-->
              </li>
              <li>
                <a href="javascript:void(0);" class="btn_search_mob"
                  ><span>Search</span></a
                >
              </li>
              <li>
                <a href="#menu" class="btn_cat_mob">
                  <div class="hamburger hamburger--spin" id="hamburger">
                    <div class="hamburger-box">
                      <div class="hamburger-inner"></div>
                    </div>
                  </div>
                  Categories
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- /row -->
      </div>
      <div class="search_mob_wp">
        <input
          type="text"
          class="form-control"
          v-model="searchText"
          :placeholder="state.headerCMS.searchPlaceholder"
        />
        <input
          type="submit"
          class="btn_1 full-width"
          @click="
            $router.push({
              name: 'Product',
              query: { keyword: searchText },
            })
          "
          value="Search"
        />
      </div>
      <!-- /search_mobile -->
    </div>
    <!-- /main_nav -->
  </header>
</template>
<script src="../js/header.js"></script>

<style scoped>
.wishListCount {
  background-color: #082687;
  color: #fff !important;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 700;
  width: 16px;
  height: 16px;
  text-indent: 0;
  display: block;
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: -3px;
  line-height: 17px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
</style>
