import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import wishListMixin from '@/wishListMixin';
export default {
    components: {
        NewsLetter,
        Advertise
    },
    mixins: [wishListMixin],
    data() {
        return {
            state,
        }
    },
    mounted() {
        this.loadCSS();
    },
    methods: {
        loadCSS: function () {
            var styles = [
                '/core/assets/css/cart.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        moveFromWishListToCart: function (data, index) {
            let duplicate = false;
            this.state.cartValue.forEach((el, index) => {
                if (el.data.id == data.data.id) {
                    // if (el.data.choosedColor === data.data.choosedColor) {
                    //     if (el.data.choosedSize === data.data.choosedSize) {
                    this.state.cartValue[index].quantity = this.state.cartValue[index].quantity + data.quantity;
                    this.state.cartCounter = this.state.cartCounter + data.quantity;
                    duplicate = true;
                    //     }
                    // }
                }
            });
            if (duplicate == true) {
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
                this.removeFromWishList(index);
            }
            if (!duplicate) {
                this.state.cartValue.push(data);
                this.state.cartCounter = this.state.cartCounter + data.quantity;
                this.removeFromWishList(index);
                this.$swal.fire(
                    "Great!!",
                    "Product added to cart successfully.",
                    'success'
                );
                localStorage.setItem(this.$cartList, JSON.stringify(this.state.cartValue));
            }
            this.calculateTotalAmount();
        },
        removeFromWishList(index) {
            this.state.wishlistCounter = this.state.wishlistCounter - this.wishList[index].quantity;
            this.wishList.splice(index, 1);
            localStorage.setItem(this.$wishList, JSON.stringify(this.wishList));
        },
    }
}