import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { state } from '@/globalVariables';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
// import $ from 'jquery';
export default {
    components: {
        NewsLetter,
        Advertise,
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            state,
            siteURL: this.$serverURL + this.$api.home.homeURL,
            silderList: [],
            featuredCategory: [],
            bestSellingList: [],
            featuredProductList: [],
            imageURL: this.$imageURL,
            singleProduct: {},
            brandList: [],
            blogList: [],
            pageCms: [],
            productReviewList: [],
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 5,
                    snapAlign: 'center',
                },
            },
        }
    },
    async created() {
        document.title = "HGS";
        this.state.isLoading = true;
        await this.getData();
        await this.loadJSCSS();
        this.scrollToTop();
    },
    methods: {
        loadJSCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/home_1.css',
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/main.js",
                "/core/assets/js/carousel-home.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
            this.state.isLoading = false;
        },
        getData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteURL,
                    headers: {
                        "Content-Type": "application/json",
                    }
                };
                await this.$axios(config).then((response) => {
                    this.silderList = response.data.sliderList;
                    this.featuredCategory = response.data.featuredCategories ? response.data.featuredCategories : [];
                    
                    this.bestSellingList = response.data.bestSellingList ? response.data.bestSellingList : [];
                    this.featuredProductList = response.data.featuredProductList ? response.data.featuredProductList : [];
                    this.singleProduct = response.data.singleProduct[0] ? response.data.singleProduct[0] : {};
                    this.blogList = response.data.latestBlog ? response.data.latestBlog : []
                    this.pageCms = response.data.homePageCMS[0] ? response.data.homePageCMS[0] : {};
                    this.customerReviewList = response.data.customerReviewList ? response.data.customerReviewList : [];
                    this.instagramFeedList = response.data.instagramFeedList ? response.data.instagramFeedList : [];
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        processWishListData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToWishList(data, quantity);
        },
        processCartData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToCart(data, quantity);
        },
    }
}
