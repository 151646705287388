//import { authHeader } from "@/auth";
import { countries} from '../../../../config/country';
// import { ref } from "vue";
import vueRecaptcha from 'vue3-recaptcha2';
import VueClientRecaptcha from "vue-client-recaptcha";
export default {
    components: {
        vueRecaptcha,
        VueClientRecaptcha
    },
      
    data() {
        return {
            rating: 0,
            inputValue: null,
            countriesList: countries,
            franchiseName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            stateName: '',
            city: '',
            zip:'',
            country: '',
            notes: '',
            isSubmit: false,
            showRecaptcha: true,
            captchaCode: '',
            token: '',
            pageCMS: [],
            url: this.$serverURL + this.$api.subscribe.franchiseCMS
        }
    },
    async mounted() {
        this.loadJSCSS();
        this.scrollToTop();
        await this.getFranchiseCMS();
    },
    methods: {
        getFranchiseCMS: async function () {

            let config = {
                method: "GET",
                url: this.url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCaptchaCode: function(value) {
            this.captchaCode = value;
        },
        checkValidCaptcha: function(value) {
            if(value == true) {
                this.isSubmit = true;
            }
            else {
                this.isSubmit = false;
            }
        },
        uploadImage(event) {
            this.image = event.target.files[0];
        },
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/leave_review.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        checkValidation: function() {
            if(!this.firstName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter firstname"
                });
                return false;
            }
            if(!this.lastName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter lastname"
                });
                return false;
            }
            if(!this.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email"
                });
                return false;
            }
            if(!this.phone) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter phone"
                });
                return false;
            }
            if(!this.stateName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter state"
                });
                return false;
            }
            if(!this.zip) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter zip code"
                });
                return false;
            }
            if(!this.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city"
                });
                return false;
            }
            if(!this.country) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter country"
                });
                return false;
            }
            if(!this.franchiseName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter franchise name"
                });
                return false;
            }
            return true;
            
        },
        addFranchise: async function () {
            this.state.isLoading = true;
            this.token = await this.recaptcha();
            if(this.checkValidation()) {
                let data = {
                    franchiseName: this.franchiseName,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    state: this.stateName,
                    zipCode: this.zip,
                    city: this.city,
                    country: this.country,
                    notes: this.notes,
                    token: this.token
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.subscribe.franchiseAPI,
                    // headers: {
                    //     "Authorization": authHeader(),
                    // }
                };
                await this.$axios(config).then(async (response) => {
                    this.state.isLoading = false;
                    if (response.data.statusCode == 201) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your franchise has been created."
                        });
                        this.firstName = '';
                        this.lastName = '';
                        this.email - '';
                        this.phone = '';
                        this.stateName = '';
                        this.city = '';
                        this.zip = '';
                        this.country = '';
                        this.notes = '';
                        this.token = '',
                        this.franchiseName = '';
                        this.$router.push('/');

                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.state.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
      
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login');
            return token;
            // Do stuff with the received token.
          }
    }
}