import { state } from "./globalVariables";
import { authHeader } from '@/auth';
export default {
    data() {
        return {
            state,
            categoriesList: [],
            orderList: [],
            pageCount: 0
        }
    },
    methods: {
        getCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productCategory,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.categoriesList = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getStatusWiseOrders: async function (params, status) {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.dashboard.filterOrder + params + "&tabStatus=" + status,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.orderList = response.data.data;
                        this.pageCount = response.data.pageCount;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        scrollToTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
        },
        logout: function () {
            localStorage.removeItem('token');
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("firstname");
            localStorage.removeItem("firstName");
            localStorage.removeItem("lastname");
            localStorage.removeItem("lastName");
            localStorage.removeItem("image");
            localStorage.removeItem("mobile");
            localStorage.removeItem("phone");
            localStorage.removeItem("_grecaptcha");
            localStorage.removeItem("usrDiscountAmount");
            localStorage.removeItem("usrDiscountType");
            this.state.token = null;

            //this.$router.push('/');
            window.location.replace("/");
        },
        clearLocalVariables() {
            localStorage.removeItem('token');
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("firstname");
            localStorage.removeItem("lastname");
            localStorage.removeItem("image");
            localStorage.removeItem("mobile");
            this.state.token = null;
        }
    }
}