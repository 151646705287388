export default {
    data() {
        return {
            cmsData: {},
            siteUrl: this.$serverURL + this.$api.policies.cancellationPolicyURL
        }
    },
    async created(){
        await this.getPageCms();
    },
    methods: {
        getPageCms: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl,
                }
                await this.$axios(config).then((response) => { 
                    if(response.data.statusCode == 200){
                        this.cmsData = response.data.data[0];
                    }
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
}