import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Slider from '@vueform/slider'
import "@vueform/slider/themes/default.css";
export default {
    components: {
        Slider,
        NewsLetter,
        Advertise,
        VPagination
    },
    data() {
        return {
            state,
            colorsList: [],
            sizesList: [],
            brandList: [],
            productList: [],
            categoryId: '',
            subCategoriesList: [],
            childCategoryList: [],
            subCategoryId: "",
            childCategoryId: "",
            brandName: '',
            colors: [],
            sizes: [],
            page: 1,
            limit: 12,
            totalPage: 0,
            pageCMS: [],
            sortBy: '',
            imagePath: this.$imageURL + 'productImage/',
            priceValue: [0, 10000],
        }
    },
    async created() {
        this.state.isLoading = true;
        this.scrollToTop();
        let params = '?page=' + this.page + '&limit=' + this.limit;
        if (this.$route.query.categoryId) {
            this.categoryId = this.$route.query.categoryId;
            params += '&parentCategoryId=' + this.$route.query.categoryId;
        }
        if (this.$route.query.brandName) {
            
            params += '&brandName=' + this.$route.query.brandName;
        }
        if (this.$route.query.subCategoryId) {
            this.subCategoryId = this.$route.query.subCategoryId;
            params += '&subCategoryId=' + this.$route.query.subCategoryId;
        }
        if (this.$route.query.keyword) {
            params += '&keyword=' + this.$route.query.keyword;
        }
        if (this.$route.query.discountTitle) {
            params += '&discountTitle=' + this.$route.query.discountTitle;
        }
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.product.productAPI + params,
        }
        let cmsURL = this.$serverURL + this.$api.product.productCMS;
        await this.getProductCMS(cmsURL);
        await this.getAllProducts(config);
        await this.getCategories();
        await this.getAllVariant();
        await this.loadJSCSS();
    },

    methods: {
        updateHandler: async function () {
            //let params = "?page=" + this.page + "&limit=" + this.limit;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.categoryId) {
                
                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.$route.query.keyword) {
                params += '&keyword=' + this.$route.query.keyword;
            }
            if (this.childCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }
            // if (this.colors.length > 0) {
            //     params += '&color=' + this.colors;
            // }
            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            if (this.$route.query.discountTitle) {
                params += '&discountTitle=' + this.$route.query.discountTitle;
            }

            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
            }
            await this.getAllProducts(config);
        },
        selectSortBy: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.sortBy) {
                params += '&sortBy=' + this.sortBy;
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
            }
            await this.getAllProducts(config);
        },
        filterProduct: async function () {
            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            if (this.categoryId) {
                params += '&parentCategoryId=' + this.categoryId;
            }
            if (this.subCategoryId) {
                params += '&subCategoryId=' + this.subCategoryId;
            }
            if (this.subCategoryId) {
                params += '&childCategoryId=' + this.childCategoryId;
            }
            if (this.brandName) {
                params += '&brandName=' + this.brandName;
            }
            // if (this.colors.length > 0) {
            //     params += '&color=' + this.colors;
            // }
            // if (this.sizes.length > 0) {
            //     params += '&size=' + this.sizes;
            // }
            if (this.priceValue) {
                params += '&minPrice=' + this.priceValue[0];
                params += '&maxPrice=' + this.priceValue[1];
            }
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
            }
            await this.getAllProducts(config);
        },
        resetFilter: async function () {
            this.categoryId = '';
            this.subCategoryId = '';
            this.childCategoryId = '';
            this.subCategoriesList = [];
            this.childCategoryList = [];
            this.brandName = '';
            this.colors = [];
            this.sizes = [];
            this.priceValue[0] = 0;
            this.priceValue[1] = 50000;

            this.page = 1;
            this.limit = 12;
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productAPI + params,
            }
            await this.getAllProducts(config);
        },
        loadJSCSS: async function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/listing.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });

            var scripts = [
                "/core/assets/js/sticky_sidebar.min.js",
                // "/core/assets/js/specific_listing.js",
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            this.state.isLoading = false;
        },
        getProductCMS: async function (url) {
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                    this.scrollToTop();

                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllVariant: async function () {
            let url = this.$serverURL + this.$api.product.productVarient;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    // this.colorsList = response.data.colorData;
                    // this.sizesList = response.data.sizeData;
                    this.brandList = response.data.brandData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getAllProducts: async function (config) {
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.productList = response.data.products.data;
                    this.totalPage = response.data.products.pageCount;
                    this.state.isLoading = false;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                    this.state.isLoading = false;
                }
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
        getSubCategories: async function (id) {
            this.subCategoriesList = [];
            this.subCategoryId = "";
            this.childCategoryList = [];
            this.childCategoryId = "";
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.productSubCategoryByParentId + '?parentCategoryId=' + id,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoriesList = await response.data.data;
                        console.log("subcategory", this.subCategoriesList);
                    }

                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        getChildCategory: async function (id) {
            this.childCategoryList = [];
            this.childCategoryId = "";
            let url = this.$serverURL + this.$api.product.childCategoryURL + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        processWishListData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToWishList(data, quantity);
        },
        processCartData(data, quantity) {
            // data.choosedColor = "N/A";
            // data.choosedSize = "N/A";
            this.addToCart(data, quantity);
        },
    },
}