<template>
    <main class="bg_gray">


        <div class="container margin_90_65">
            <div class="main_title">
                <h1>{{cms.title}}</h1>
                <p>{{cms.subTitle}}</p>
            </div>
            <!-- <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="search-input white">
                        <input type="text" :placeholder="cms.searchText">
                        <button type="submit"><i class="ti-search"></i></button>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- /container -->

        <div class="bg_white">
            <div class="container margin_90_65">
                <div class="main_title">
                    <h2>{{cms.supportSectionTitle}}</h2>
                    <p>{{cms.supportSectionSubTitle}}</p>
                </div>
                <div class="row">
                    <div class="col-md-6" v-for="(data, index) in content" :key="index">
                        <a class="box_topic_2" href="#0">
                            <i :class="data.iconClass"></i>
                            <h3>{{data.title}}</h3>
                            <p v-html="data.description"></p>
                        </a>
                    </div>
                </div>
            </div>
            <!-- /container -->
        </div>
        <!-- /bg_white -->
    </main>
    <Advertise v-if="state.advertiseList.includes('Help') && state.isAdvertise==true"/>
    <NewsLetter v-if="state.newsletterList.includes('Help') && state.isNewsLetter==true"/>
</template>
<script src="../js/help.js"></script>