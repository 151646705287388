import siderbar from '../template/SideBar'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components:{
        siderbar,
        VPagination
    },
    data() {
        return {
            page: 1,
            limit: 6,
            totalPage: 0
        }
    },
    async mounted () {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        await this.getStatusWiseOrders(params, 'pending');
        this.totalPage = this.pageCount;
    },
    methods: {
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            await this.getStatusWiseOrders(params, 'pending');
            this.totalPage = this.pageCount;
        },
    },
    
}