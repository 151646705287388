import $ from 'jquery';
import siderbar from '../template/SideBar'
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import { authHeader } from '@/auth';

export default {
    components: {
        siderbar,
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            isButtonHidden: false,
            isShippingButtonHidden: false,
            isBillingButtonHidden: false,
            firstName: '',
            lastName: '',
            businessName: '',
            mobile: '',
            gender: '',
            dob: '',
            address: '',
            city: '',
            stateCode: '',
            postalCode: '',
            country: {
                name: 'United States of America',
                code: 'US',
            },
            image: '',
            previewImage: '',
            siteUrl: this.$serverURL + this.$api.profile.userProfileData,
            profileData: {},


            //shipping data
            shippingAddressData: {},

            //billing data
            billingAddressData: {},

        }
    },
    async created() {
        await this.getProfileData();
        this.scrollToTop();
    },
    methods: {
        async getProfileData() {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.profileData = response.data.data.userData;
                        this.firstName = this.profileData.firstName ? this.firstName = this.profileData.firstName : localStorage.getItem("firstName");
                        this.lastName = this.profileData.lastName ? this.lastName = this.profileData.lastName : localStorage.getItem("lastName");


                        this.email = this.profileData.email ? this.email = this.profileData.email : localStorage.getItem("email");
                        this.mobile = this.profileData.mobile ? this.mobile = this.profileData.mobile : localStorage.getItem("mobile");
                        if (this.mobile == 'undefined') {
                            this.mobile = ''
                        }
                        this.gender = this.profileData.gender ? this.gender = this.profileData.gender : localStorage.getItem('gender');
                        this.address = this.profileData.fullAddress ? this.address = this.profileData.fullAddress : localStorage.getItem("address");
                        this.city = this.profileData?.city ? this.city = this.profileData.city : localStorage.getItem("city");
                        this.postalCode = this.profileData?.postalCode ? this.postalCode = this.profileData.postalCode : localStorage.getItem("postalCode");
                        localStorage.setItem('image', this.profileData.image);
                        this.state.profileImage = this.profileData?.image ? this.state.profileImage = this.profileData?.image : localStorage.getItem("image")
                        this.formatDate = new Date(this.profileData?.dateOfBirth);
                        if (this.profileData?.dateOfBirth === null) {
                            this.dob = null
                        } else {
                            this.dob = this.formatDate.getFullYear() + '-' + String((this.formatDate.getMonth() + 1)).padStart(2, '0') + '-' + String(this.formatDate.getDate()).padStart(2, '0');
                        }
                        this.state.fullName = this.firstName + (this.lastName ? this.lastName : '');

                        if (response.data?.data?.shippingAddress) {
                            this.shippingAddressData = response.data.data.shippingAddress;
                        } else {
                            this.shippingAddressData = {
                                name: null,
                                businessName: null,
                                addressOne: null,
                                addressTwo: null,
                                mobile: null,
                                email: null,
                                stateCode: null,
                                postCode: null,
                                city: null,
                            }
                        }
                        if (response.data.data.billingAddress) {
                            this.billingAddressData = response.data?.data?.billingAddress;
                        } else {
                            this.billingAddressData = {
                                name: null,
                                businessName: null,
                                address: null,
                                mobile: null,
                                email: null,
                                stateCode: null,
                                postCode: null,
                                city: null,
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        uploadProfileImage(event) {
            this.image = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        editPersonalInfo() {
            $('#personalInfo input').attr('disabled', !this.isButtonHidden);
            $('#personalInfo select').attr('disabled', !this.isButtonHidden);
            this.isButtonHidden = !this.isButtonHidden;
        },
        async updatePersonalInfo() {
            $('#personalInfo input').attr('disabled', true);
            $('#personalInfo select').attr('disabled', true);
            this.isButtonHidden = true;
            if (!this.firstName) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter first name"
                });
            } else if (!this.lastName) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter last name"
                });
            } else if (!this.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "please enter street address"
                });
            } else if (!this.gender) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide gender"
                });
            } else if (!this.dob) {
                this.$swal.fire({
                    icon: "error",
                    text: "please provide date of birth"
                });
            } else {
                try {
                    let formData = new FormData();
                    formData.append("firstName", this.firstName);
                    formData.append("lastName", this.lastName);
                    formData.append("businessName", this.businessName);
                    formData.append("mobile", this.mobile);
                    formData.append("gender", this.gender);
                    formData.append("dateOfBirth", this.dob);
                    formData.append("fullAddress", this.address);
                    formData.append("state", this.stateCode);
                    formData.append("postalCode", this.postalCode);
                    formData.append("city", this.city);
                    formData.append("country", this.country.name);
                    if (this.image) {
                        formData.append("image", this.image);
                    } else {
                        formData.append("image", this.state.profileImage);
                    }
                    let config = {
                        method: "PATCH",
                        url: this.$serverURL + this.$api.profile.updateUserProfile,
                        data: formData,
                        headers: {
                            "Authorization": authHeader()
                        }
                    }
                    await this.$axios(config).then((response) => {
                        if (response.data.statusCode == 200) {

                            this.profileData = response.data.data;

                            let name = this.profileData.firstName + " " + this.profileData.lastName;
                            localStorage.setItem("name", name);
                            localStorage.setItem('firstname', this.profileData.firstName);
                            localStorage.setItem('lastname', this.profileData.lastName);
                            localStorage.setItem("email", this.profileData.email);
                            localStorage.setItem("mobile", this.profileData.mobile);


                            this.firstName = this.profileData.firstName ? this.firstName = this.profileData.firstName : localStorage.getItem("firstName");
                            this.lastName = this.profileData.lastName ? this.lastName = this.profileData.lastName : localStorage.getItem("lastName");
                            this.businessName = this.profileData.businessName ? this.profileData.businessName : '';
                            this.email = this.profileData.email ? this.email = this.profileData.email : localStorage.getItem("email");
                            this.mobile = this.profileData.mobile ? this.mobile = this.profileData.mobile : localStorage.getItem("mobile");
                            this.gender = this.profileData.gender ? this.gender = this.profileData.gender : localStorage.getItem('gender');
                            this.address = this.profileData.fullAddress ? this.address = this.profileData.fullAddress : localStorage.getItem("address");
                            this.city = this.profileData?.city ? this.city = this.profileData.city : localStorage.getItem("city");
                            this.postalCode = this.profileData?.postalCode ? this.postalCode = this.profileData.postalCode : localStorage.getItem("postalCode");
                            localStorage.setItem('image', this.profileData.image);
                            this.state.profileImage = this.profileData?.image ? this.state.profileImage = this.profileData?.image : localStorage.getItem("image")
                            this.formatDate = new Date(this.profileData?.dateOfBirth);
                            this.dob = this.formatDate.getFullYear() + '-' + String((this.formatDate.getMonth() + 1)).padStart(2, '0') + '-' + String(this.formatDate.getDate()).padStart(2, '0');
                            this.state.fullName = this.firstName + " " + this.lastName;
                            this.image = "",
                                this.$swal.fire({
                                    icon: "success",
                                    text: response.data.message,
                                });
                        }
                        else {
                            this.$swal.fire({
                                icon: "error",
                                text: "Failed to update!"
                            });
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed to update!"
                    });
                }
            }


        },
        editShippingAddress() {
            $('#shippingAddress input').attr('disabled', !this.isShippingButtonHidden);
            $('#shippingAddress select').attr('disabled', !this.isShippingButtonHidden);
            this.isShippingButtonHidden = !this.isShippingButtonHidden
        },
        validateShippingAdd: function () {
            if (!this.shippingAddressData.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!",
                });
                return false;
            }
            if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address!",
                });
                return false;
            }
            if (!this.shippingAddressData.mobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter mobile!",
                });
                return false;
            }
            if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "error",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            }
            if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!",
                });
                return false;
            }
            if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter postal code!",
                });
                return false;
            }
            if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city!",
                });
                return false;
            }
            return true
        },
        async saveShippingAddress() {
            $('#shippingAddress input').attr('disabled', true);
            $('#shippingAddress select').attr('disabled', true);
            this.isShippingButtonHidden = true;
            if (this.validateShippingAdd()) {
                try {
                    let data = {
                        name: this.shippingAddressData.name,
                        businessName: this.shippingAddressData.businessName,
                        addressOne: this.shippingAddressData.addressOne,
                        addressTwo: this.shippingAddressData.addressTwo,
                        mobile: this.shippingAddressData.mobile,
                        email: this.shippingAddressData.email,
                        stateCode: this.shippingAddressData.stateCode,
                        postCode: this.shippingAddressData.postCode,
                        city: this.shippingAddressData.city,
                        country: this.country.name,
                        countryCode: this.country.code
                    };
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.profile.shippingAddressURL,
                        data: data,
                        headers: {
                            "Authorization": authHeader()
                        }

                    };
                    await this.$axios(config).then((response) => {
                        if (response.data.statusCode) {
                            this.shippingAddressData = response.data.data;
                            this.shippingCountry = "";
                            this.$swal.fire({
                                icon: "success",
                                text: response.data.message,
                            });
                        } else {
                            this.$swal.fire({
                                icon: "info",
                                text: response.data.message,
                            });
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                }
            }

        },
        editBillingAddress() {
            $('#billingAddress input').attr('disabled', !this.isBillingButtonHidden);
            $('#billingAddress select').attr('disabled', !this.isBillingButtonHidden);
            this.isBillingButtonHidden = !this.isBillingButtonHidden
        },
        validateBillingAdd: function () {
            if (!this.billingAddressData.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter name!",
                });
                return false;
            }
            if (!this.billingAddressData.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter address!",
                });
                return false;
            }
            if (!this.billingAddressData.mobile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter mobile!",
                });
                return false;
            }
            if (this.shippingAddressData.mobile.length < 9) {
                this.$swal.fire({
                    icon: "error",
                    text: "Mobile number must be at least 10 digit",
                });
                return false;
            }
            if (!this.billingAddressData.email) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter email!",
                });
                return false;
            }
            if (!this.billingAddressData.postCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter postal code!",
                });
                return false;
            }
            if (!this.billingAddressData.city) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter city!",
                });
                return false;
            }
            return true
        },
        async saveBillingAddress() {
            $('#billingAddress input').attr('disabled', true);
            $('#billingAddress select').attr('disabled', true);
            this.isBillingButtonHidden = true;
            if (this.validateBillingAdd()) {
                try {
                    let data = {
                        name: this.billingAddressData.name,
                        businessName: this.billingAddressData.businessName,
                        address: this.billingAddressData.address,
                        mobile: this.billingAddressData.mobile,
                        email: this.billingAddressData.email,
                        stateCode: this.shippingAddressData.stateCode,
                        postCode: this.billingAddressData.postCode,
                        city: this.billingAddressData.city,
                        country: this.country.name,
                        countryCode: this.country.code
                    };
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.profile.billingAddressURL,
                        data: data,
                        headers: {
                            "Authorization": authHeader()
                        }

                    };
                    await this.$axios(config).then((response) => {
                        if (response.data.statusCode) {
                            this.billingAddressData = response.data.data;
                            this.billingCountry = "";
                            this.$swal.fire({
                                icon: "success",
                                text: response.data.message,
                            });
                        } else {
                            this.$swal.fire({
                                icon: "info",
                                text: response.data.message,
                            });
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                }
            }

        }
    },
    mounted() {
        this.isButtonHidden = true
        this.isShippingButtonHidden = true
        this.isBillingButtonHidden = true
        $('input,select').attr('disabled', true);

    }
}