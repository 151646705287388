<template>
  <main class="bg_gray">
    <div class="container margin_60_35 add_bottom_30">
      <div class="main_title">
        <h2>{{cmsData.title}}</h2>
      </div>
      <div class="row justify-content-center align-items-center">
        <p v-html="cmsData.description"></p>
      </div>
    </div>
  </main>
</template>
<script src="../js/terms-and-condition.js"></script>