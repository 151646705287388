import { authHeader } from "../../../auth";
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        NewsLetter,
        Advertise,
        VPagination
    },
    data() {
        return {
            state,
            blogPageCMS: {},
            blogPostList: [],
            blogCategoryList: [],
            latestBlogs: [],
            blogTagList: [],
            keyword: '',
            page: 1,
            limit: 6,
            totalPage: 0,
            imagePath: this.$imageURL + 'blog/',
            setURL: this.$serverURL + this.$api.blog.blogPageAPI,
            params: '',
            categoryId: '',
            tag: '',
        }

    },
    created() {
        document.title = "HGS-Blog";
        this.state.isLoading = true;
        this.scrollToTop();
    },
    async mounted() {
        this.params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.$route.query.categoryId) {
            this.params += "&categoryId=" + this.$route.query.categoryId;
        }
        if (this.$route.query.keyword) {
            this.params += "&keyword=" + this.this.$route.query.keyword;
        }
        if (this.$route.query.tag) {
            this.params += "&tag=" + this.$route.query.tag;
        }

        let config = {
            method: "GET",
            url: this.setURL + this.params,
            headers: {
                "Authorization": authHeader()
            }
        };
        await this.getBlogList(config);
        this.loadCSS();
    },
    methods: {
        updateHandler: async function () {
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.$route.query.categoryId) {
                this.params += "&categoryId=" + this.$route.query.categoryId;
            }
            if (this.$route.query.keyword) {
                this.params += "&keyword=" + this.this.$route.query.keyword;
            }
            if (this.$route.query.tag) {
                this.params += "&tagName=" + this.$route.query.tag;
            }

            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);
        },
        loadCSS: function () {
            this.state.isLoading = true;
            var styles = [
                '/core/assets/css/blog.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
            this.state.isLoading = false;
        },
        searchCategory: async function (catId) {
            this.page = 1;
            this.limit = 6;
            this.categoryId = catId;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);

        },
        searchKeyword: async function () {
            this.page = 1;
            this.limit = 6;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);
        },
        searchTag: async function (tagName) {
            this.page = 1;
            this.limit = 6;
            this.tag = tagName;
            this.params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.categoryId) {
                this.params += "&categoryId=" + this.categoryId;
            }
            if (this.keyword) {
                this.params += "&keyword=" + this.keyword;
            }
            if (this.tag) {
                this.params += "&tagName=" + this.tag;
            }
            let config = {
                method: "GET",
                url: this.setURL + this.params,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.getBlogList(config);

        },
        getBlogList: async function (config) {
            this.state.isLoading = true;
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.blogPageCMS = await response.data.data.cmsData;
                    this.blogPostList = await response.data.data.blogList.posts;
                    this.totalPage = await response.data.data.blogList.pageCount;
                    this.blogCategoryList = await response.data.data.blogCategoryList;
                    this.blogTagList = await response.data.data.blogTagList;
                    this.latestBlogs = await response.data.data.latestBlogList
                    this.state.isLoading = false;
                }
                this.state.isLoading = false;
            }).catch(error => {
                this.state.isLoading = false;
                console.log(error);
            });
        },
    }
}