<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="page_header">
        <div class="breadcrumbs">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Category</a></li>
            <li>Page active</li>
          </ul>
        </div>
        <h1>Checkout and Payment</h1>
      </div>
      <!-- /page_header -->
      <div class="row">
        <div v-if="!showCard" class="col-lg-4 col-md-6">
          <div class="step first">
            <h3># Shipping and Billing address</h3>
            <ul class="nav nav-tabs" id="tab_checkout" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  href="#tab_1"
                  role="tab"
                  aria-controls="tab_1"
                  aria-selected="true"
                  >Billing Address</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#tab_2"
                  role="tab"
                  aria-controls="tab_2"
                  aria-selected="false"
                  >Shipping Address</a
                >
              </li>
            </ul>
            <div class="tab-content checkout">
              <div
                class="tab-pane fade show active"
                id="tab_1"
                role="tabpanel"
                aria-labelledby="tab_1"
              >
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Billing name"
                    @change="checkSB"
                    v-model="billingAddressData.name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Business name"
                    v-model="billingAddressData.businessName"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Street Address"
                    @change="checkSB"
                    v-model="billingAddressData.address"
                  />
                </div>
                <hr />
                <div class="col-12 form-group pr-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="City"
                    @change="checkSB"
                    v-model="billingAddressData.city"
                  />
                </div>
                <div class="row no-gutters">
                  <div class="col-6 form-group pl-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Zip code"
                      @change="checkSB"
                      v-model="billingAddressData.postCode"
                    />
                  </div>
                  <div class="col-6 form-group pr-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="State Name"
                      @change="checkSB"
                      v-model="billingAddressData.stateCode"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile No"
                    @change="checkSB"
                    v-model="billingAddressData.mobile"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="billingAddressData.email"
                  />
                </div>
                <div class="form-group" hidden>
                  <select name="country" v-model="country" class="form-control">
                    <option
                      v-for="(data, index) in countriesList"
                      :key="index"
                      :value="{
                        name: data.countryNameEn,
                        code: data.countryCode,
                      }"
                    >
                      {{ data.countryNameEn }}
                    </option>
                  </select>
                </div>
                <!-- /row -->
                <hr />
              </div>
              <!-- /tab_1 -->
              <div
                class="tab-pane fade"
                id="tab_2"
                role="tabpanel"
                aria-labelledby="tab_2"
                style="position: relative"
              >
                <div class="form-group">
                  <label class="container_check"
                    >Same as billing address
                    <input
                      v-model="sameAsBillingAddress"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Shipping name"
                    @change="checkSB"
                    v-model="shippingAddressData.name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Business name"
                    v-model="shippingAddressData.businessName"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Street Address One"
                    @change="checkSB"
                    v-model="shippingAddressData.addressOne"
                  />
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Street Address Two"
                    v-model="shippingAddressData.addressTwo"
                  />
                </div>

                <hr />
                <div class="row no-gutters">
                  <div class="col-12 form-group pr-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      @change="checkSB"
                      v-model="shippingAddressData.city"
                    />
                  </div>
                  <div class="col-6 form-group pl-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Zip code"
                      @change="checkSB"
                      v-model="shippingAddressData.postCode"
                    />
                  </div>
                  <div class="col-6 form-group pr-1">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="State Name"
                      @change="checkSB"
                      v-model="shippingAddressData.stateCode"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile No"
                    @change="checkSB"
                    v-model="shippingAddressData.mobile"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    @change="checkSB"
                    v-model="shippingAddressData.email"
                  />
                </div>
                <div class="form-group" hidden>
                  <select name="country" v-model="country" class="form-control">
                    <option
                      v-for="(data, index) in countriesList"
                      :key="index"
                      :value="{
                        name: data.countryNameEn,
                        code: data.countryCode,
                      }"
                    >
                      {{ data.countryNameEn }}
                    </option>
                  </select>
                </div>
                <!-- /row -->
                <hr />
              </div>
              <!-- /tab_2 -->
            </div>
          </div>
          <!-- /step -->
        </div>
        <div v-if="!showCard" class="col-lg-4 col-md-6">
          <div class="step middle payments">
            <h3># Shipping</h3>
            <!-- <ul>
              <li
                v-for="(data, index) in this.state.paymentMethods"
                :key="index"
              >
                <label class="container_radio"
                  >{{ data.name
                  }}<a
                    href="javascript:void(0)"
                    class="info"
                    data-bs-toggle="modal"
                    data-bs-target="#payments_method"
                  ></a>
                  <input
                    type="radio"
                    name="payment"
                    v-model="paymentMethod"
                    :value="data.name"
                  />
                  <span class="checkmark"></span>
                </label>
              </li>
            </ul> -->
            <h6 class="pb-2">Shipping Method</h6>
            <div class="alert alert-primary" role="alert" v-if="disableShipping">
              Please Provide shipping and billing address to proceed
            </div>
            <div class="alert alert-success" role="alert" v-else>
              Choose Shipping Method
            </div>
            <ul
              id="shipping_methods"
              :style="[disableShipping ? { 'pointer-events': 'none' } : '']"
            >
              <li>
                <label class="container_radio"
                  >Fedex<a href="javascript:void(0)" class="float-end"
                    ><img
                      src="../../../../public/core/assets/img/fedx.png"
                      width="70"
                      height="34"
                      alt=""
                  /></a>
                  <input
                    type="radio"
                    v-model="shippingType"
                    value="fedex"
                    @change="handleShipping"
                    name="shipping"
                  />
                  <span class="checkmark"></span>
                </label>
              </li>
              <li>
                <label class="container_radio"
                  >UPS<a href="javascript:void(0)" class="float-end"
                    ><img
                      src="../../../../public/core/assets/img/ups.png"
                      width="70"
                      height="34"
                      alt=""
                  /></a>
                  <input
                    type="radio"
                    v-model="shippingType"
                    value="ups"
                    @change="handleShipping"
                    name="shipping"
                  />
                  <span class="checkmark"></span>
                </label>
              </li>
            </ul>
            <div class="row no-gutters" v-if="shippingType == 'fedex'">
              <h6 class="pb-2">Service Type</h6>
              <div class="form-group">
                <ul>
                  <li v-for="(data, index) in serviceTypeList" :key="index">
                    <label class="container_radio"
                      >{{ data.serviceName
                      }}<a
                        href="javascript:void(0)"
                        class="float-end"
                        style="color: black"
                        >${{ data.price }}</a
                      >
                      <input
                        type="radio"
                        v-model="serviceType"
                        @click="setShippingCost(data.price)"
                        :value="data.serviceType"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row no-gutters" v-if="shippingType == 'ups'">
              <h6 class="pb-2">Service Type</h6>
              <div class="form-group">
                <ul>
                  <li v-for="(data, index) in serviceTypeList" :key="index">
                    <label class="container_radio"
                      >{{ data.serviceName
                      }}<a
                        href="javascript:void(0)"
                        class="float-end"
                        style="color: black"
                        >${{ data.price }}</a
                      >
                      <input
                        type="radio"
                        v-model="serviceType"
                        @click="setShippingCost(data.price)"
                        :value="data.serviceType"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                v-model="remarks"
                placeholder="Remarks"
                id="floatingTextarea2"
                style="height: 100px"
              ></textarea>
            </div>
          </div>
          <!-- /step -->
        </div>

        <!-- payment section -->
        <div v-if="showCard" class="col-lg-8 col-md-6">
          <div class="step middle payments">
            <h3># Card Information</h3>
            <!-- <div class="payment-title">
              <h1>Payment Information</h1>
            </div> -->
            <div class="row">
              <div class="col-sm-6">
                <div class="payment-container preload">
                  <div class="creditcard">
                    <div class="front">
                      <div id="ccsingle"></div>
                      <svg
                        version="1.1"
                        id="cardfront"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471"
                        xml:space="preserve"
                      >
                        <g id="Front">
                          <g id="CardBackground">
                            <g id="Page-1_1_">
                              <g id="amex_1_">
                                <path
                                  id="Rectangle-1_1_"
                                  class="lightcolor grey"
                                  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z"
                                />
                              </g>
                            </g>
                            <path
                              class="darkcolor greydark"
                              d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"
                            />
                          </g>
                          <text
                            transform="matrix(1 0 0 1 60.106 295.0121)"
                            id="svgnumber"
                            class="st2 st3 st4"
                          >
                            {{
                              paymentInfo.cardNumber
                                ? paymentInfo.cardNumber
                                : "0123 4567 8910 1112"
                            }}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 54.1064 428.1723)"
                            id="svgname"
                            class="st2 st5 st6"
                          >
                            {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                          <text
                            transform="matrix(1 0 0 1 54.1074 389.8793)"
                            class="st7 st5 st8"
                          >
                            cardholder name
                          </text>
                          <text
                            transform="matrix(1 0 0 1 479.7754 388.8793)"
                            class="st7 st5 st8"
                          >
                            expiration
                          </text>
                          <text
                            transform="matrix(1 0 0 1 65.1054 241.5)"
                            class="st7 st5 st8"
                          >
                            card number
                          </text>
                          <g>
                            <text
                              transform="matrix(1 0 0 1 574.4219 433.8095)"
                              id="svgexpire"
                              class="st2 st5 st9"
                            >
                              {{ expireDate ? expireDate : "01/23" }}
                            </text>
                            <text
                              transform="matrix(1 0 0 1 479.3848 417.0097)"
                              class="st2 st10 st11"
                            >
                              VALID
                            </text>
                            <text
                              transform="matrix(1 0 0 1 479.3848 435.6762)"
                              class="st2 st10 st11"
                            >
                              THRU
                            </text>
                            <polygon
                              class="st2"
                              points="554.5,421 540.4,414.2 540.4,427.9 		"
                            />
                          </g>
                          <g id="cchip">
                            <g>
                              <path
                                class="st2"
                                d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                              />
                            </g>
                            <g>
                              <g>
                                <rect
                                  x="82"
                                  y="70"
                                  class="st12"
                                  width="1.5"
                                  height="60"
                                />
                              </g>
                              <g>
                                <rect
                                  x="167.4"
                                  y="70"
                                  class="st12"
                                  width="1.5"
                                  height="60"
                                />
                              </g>
                              <g>
                                <path
                                  class="st12"
                                  d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                />
                              </g>
                              <g>
                                <rect
                                  x="82.8"
                                  y="82.1"
                                  class="st12"
                                  width="25.8"
                                  height="1.5"
                                />
                              </g>
                              <g>
                                <rect
                                  x="82.8"
                                  y="117.9"
                                  class="st12"
                                  width="26.1"
                                  height="1.5"
                                />
                              </g>
                              <g>
                                <rect
                                  x="142.4"
                                  y="82.1"
                                  class="st12"
                                  width="25.8"
                                  height="1.5"
                                />
                              </g>
                              <g>
                                <rect
                                  x="142"
                                  y="117.9"
                                  class="st12"
                                  width="26.2"
                                  height="1.5"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="Back"></g>
                      </svg>
                    </div>
                    <div class="back">
                      <svg
                        version="1.1"
                        id="cardback"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471"
                        xml:space="preserve"
                      >
                        <g id="Back">
                          <line
                            class="st0"
                            x1="35.3"
                            y1="10.4"
                            x2="36.7"
                            y2="11"
                          />
                        </g>
                        <g id="Back">
                          <g id="Page-1_2_">
                            <g id="amex_2_">
                              <path
                                id="Rectangle-1_2_"
                                class="darkcolor greydark"
                                d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z"
                              />
                            </g>
                          </g>
                          <rect y="61.6" class="st2" width="750" height="78" />
                          <g>
                            <path
                              class="st3"
                              d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z"
                            />
                            <rect
                              x="42.9"
                              y="198.6"
                              class="st4"
                              width="664.1"
                              height="10.5"
                            />
                            <rect
                              x="42.9"
                              y="224.5"
                              class="st4"
                              width="664.1"
                              height="10.5"
                            />
                            <path
                              class="st5"
                              d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                            />
                          </g>
                          <text
                            transform="matrix(1 0 0 1 621.999 227.2734)"
                            id="svgsecurity"
                            class="st6 st7"
                          >
                            {{
                              paymentInfo.securityCode
                                ? paymentInfo.securityCode
                                : "985"
                            }}
                          </text>
                          <g class="st8">
                            <text
                              transform="matrix(1 0 0 1 518.083 280.0879)"
                              class="st9 st6 st10"
                            >
                              security code
                            </text>
                          </g>
                          <rect
                            x="58.1"
                            y="378.6"
                            class="st11"
                            width="375.5"
                            height="13.5"
                          />
                          <rect
                            x="58.1"
                            y="405.6"
                            class="st11"
                            width="421.7"
                            height="13.5"
                          />
                          <text
                            transform="matrix(1 0 0 1 59.5073 228.6099)"
                            id="svgnameback"
                            class="st12 st13"
                          >
                            {{
                              paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                            }}
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-container">
                  <div class="field-container">
                    <label class="payment-label" for="name">Name</label>
                    <input
                      class="payment-input"
                      id="name"
                      maxlength="20"
                      type="text"
                      v-model="paymentInfo.name"
                    />
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="cardnumber"
                      >Card Number</label
                    >
                    <!-- <span id="generatecard">generate random</span> -->
                    <input
                      id="cardnumber"
                      type="text"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      class="payment-input"
                      v-model="paymentInfo.cardNumber"
                    />
                    <svg
                      id="ccicon"
                      class="ccicon"
                      width="750"
                      height="471"
                      viewBox="0 0 750 471"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    ></svg>
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="expirationdate"
                      >Expiration (mm/yy)</label
                    >
                    <input
                      id="expirationdate"
                      class="payment-input"
                      type="text"
                      maxlength="5"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      v-model="expireDate"
                    />
                  </div>
                  <div class="field-container">
                    <label class="payment-label" for="securitycode"
                      >Security Code</label
                    >
                    <input
                      id="securitycode"
                      class="payment-input"
                      type="text"
                      maxlength="4"
                      pattern="[0-9]*"
                      v-model="paymentInfo.securityCode"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /step -->
        </div>

        <!-- payment section -->

        <div class="col-lg-4 col-md-6">
          <div class="step last">
            <h3># Order and Payment Summary</h3>
            <div class="box_general summary">
              <ul>
                <li
                  class="clearfix"
                  v-for="(data, index) in state.cartValue"
                  :key="index"
                >
                  <em>{{ data.quantity }}x {{ data.data.name }}</em>
                  <span
                    >${{
                      data.data.discountedPrice
                        ? data.data.discountedPrice
                        : data.data.price
                    }}</span
                  >
                </li>
              </ul>
              <ul>
                <li class="clearfix">
                  <em><strong>Subtotal</strong></em>
                  <span>${{ state.totalAmount }}</span>
                </li>
                <li class="clearfix" v-if="shippingCost">
                  <em><strong>Shipping cost</strong></em>
                  <span>${{ shippingCost }}</span>
                </li>
                <li class="clearfix" v-if="state.couponDiscount">
                  <em><strong>Coupon discount</strong></em>
                  <span>- ${{ state.couponDiscount }}</span>
                </li>
              </ul>
              <div class="total clearfix">
                TOTAL
                <span
                  >${{
                    state.couponDiscount
                      ? (
                          parseFloat(state.totalAmount + shippingCost) -
                          state.couponDiscount
                        ).toFixed(2)
                      : parseFloat(state.totalAmount + shippingCost).toFixed(2)
                  }}</span
                >
              </div>
            </div>
            <button
              type="button"
              v-if="!orderConfirmed"
              @click="confirmedOrder"
              class="btn_1 full-width"
            >
              Place Order
            </button>
            <div class="step middle payments" v-if="orderConfirmed">
              <h6 class="pb-2">Payment Method:</h6>
              <ul>
                <li
                  v-for="(data, index) in this.state.paymentMethods"
                  :key="index"
                >
                  <label class="container_radio"
                    >{{ data.name }}
                    <input
                      type="radio"
                      name="payment"
                      v-model="paymentMethod"
                      :value="data.name"
                    />
                    <span class="checkmark"></span>
                  </label>
                </li>
              </ul>
              <!-- <a
                v-if="!showCard"
                href="javascript:void(0)"
                @click="handleOrder"
                class="btn_1 full-width"
                >Place Order</a
              > -->
              <button
                type="button"
                @click="handlePayment"
                class="btn_1 full-width"
              >
                Confirm and Pay
              </button>
            </div>
            <!-- /box_general -->
          </div>
          <!-- /step -->
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </main>
  <Advertise
    v-if="state.advertiseList.includes('Checkout') && state.isAdvertise == true"
  />
  <NewsLetter
    v-if="
      state.newsletterList.includes('Checkout') && state.isNewsLetter == true
    "
  />
</template>
<script src="../js/checkout.js"></script>
<style scoped>
@import "../css/checkout.css";
</style>