<template>
    <main class="bg_gray">

        <div class="container margin_60">
            <div class="main_title">
                <h2>{{pageCMS.title}}</h2>
                <p>{{pageCMS.subTitle}}.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4" v-for="(data, index) in contentData" :key="index">
                    <div class="box_contacts">
                        <i :class="data.icon"></i>
                        <h2>{{data.title}}</h2>
                        <div class="container" v-html="data.description.replace(/(&gt;)(?:&nbsp;|<br>)+(\s?&lt;)/g,'$1$2')"></div>
                        
                    </div>
                </div>
                
            </div>
            <!-- /row -->
        </div>
        <!-- /container -->
        <div class="bg_white">
            <div class="container margin_60_35">
                <h4 class="pb-3">{{pageCMS.contactFormTitle}}</h4>
                <div class="row">
                    <div class="col-lg-4 col-md-6 add_bottom_25">
                        <div class="form-group">
                            <input class="form-control" v-model="name" type="text" placeholder="Name *">
                        </div>
                        <div class="form-group">
                            <input class="form-control" v-model="email" type="email" placeholder="Email *">
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" v-model="message" style="height: 150px;" placeholder="Message *"></textarea>
                        </div>
                        <div class="form-group">
                            <input class="btn_1 full-width" @click="sendMessage" type="button" :value="pageCMS.buttonText">
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6 add_bottom_25">
                        <iframe class="map_contact"
                            :src="pageCMS.googleMapLink"
                            style="border: 0" allowfullscreen></iframe>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /bg_white -->
    </main>
    <Advertise v-if="state.advertiseList.includes('Contact') && state.isAdvertise==true"/>
    <NewsLetter v-if="state.newsletterList.includes('Contact') && state.isNewsLetter==true"/>
</template>
<script src="../js/contact.js"></script>