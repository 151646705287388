import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            couponList: [],
            couponCode: "",
        }
    },
    async created() {
        this.scrollToTop();
        await this.getCoupons();
        console.log("cart:", this.state.cartValue);
    },
    mounted() {
        this.loadCSS();
    },
    methods: {
        loadCSS: function () {
            var styles = [
                '/core/assets/css/cart.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
        async getCoupons() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.coupon.getCouponsURL,
                    header: {
                        'Content-Type': "application/json"
                    }
                };
                await this.$axios(config).then(response => {
                    this.couponList = response.data.data;
                }).catch(error => {
                    console.log(error);
                })
            } catch (error) {
                console.log(error);
            }
        },
        applyCoupon() {
            let data = this.couponList.find(el => el.couponCode == this.couponCode)
            if (data) {
                this.state.couponId = data.id;
                if (data.couponType == 'percent') {
                    this.state.couponDiscount = this.state.totalAmount * (parseFloat(data.discount) / 100);
                } else {
                    this.state.couponDiscount = parseFloat(data.discount);
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Not a valid coupon!!!"
                });
            }
        }
    }
}