import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
export default {
    components: {
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            cms: {},
            content: {}
        }
    },
    created() {
        this.scrollToTop();
    },
    async mounted() {
        this.loadCSS();
        await this.getHelpPageData();
    },
    methods: {
        getHelpPageData: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.help.helpPageURL,
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.cms = await response.data.data.cmsData;
                        this.content = await response.data.data.contentData;
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
        loadCSS: function () {
            var styles = [
                '/core/assets/css/faq.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
        },
    }
}