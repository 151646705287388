<template>
  <main>
    <div id="carousel-home" class="clearfix">
      <div class="owl-carousel owl-theme" v-if="silderList.length > 0">
        <div v-for="(data, index) in silderList" :key="index">
          <div
            class="owl-slide cover"
            :style="{
              'background-image': `url(${imageURL}home/${data.backgroundImage})`,
            }"
          >
            <div
              class="opacity-mask d-flex align-items-center"
              data-opacity-mask="rgba(0, 0, 0, 0.5)"
            >
              <div class="container">
                <div class="row justify-content-center justify-content-md-end">
                  <div class="col-lg-6 static">
                    <div class="slide-text text-end white">
                      <h2 class="owl-slide-animated owl-slide-title">
                        {{ data.title }}
                      </h2>
                      <p class="owl-slide-animated owl-slide-subtitle">
                        {{ data.subTitle }}
                      </p>
                      <div class="owl-slide-animated owl-slide-cta">
                        <a
                          class="btn_1"
                          href="javascript:void(0)"
                          role="button"
                          @click="$router.push(data.buttonLink)"
                          >{{ data.buttonText }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="icon_drag_mobile"></div>
    </div>
    <!--/carousel-->

    <ul
      id="banners_grid"
      class="clearfix"
      v-if="featuredCategory && featuredCategory.length > 0"
    >
      <li v-for="(data, index) in featuredCategory" :key="index">
        <a
          href="javascript:void(0)"
          @click="
            $router.push({
              name: 'Product',
              query: { categoryId: data.id },
            })
          "
          class="img_container"
        >
          <img
            v-if="data.image"
            :src="imageURL + 'featured-categories-image/' + data.image"
            :data-src="imageURL + 'featured-categories-image/' + data.image"
            alt=""
            class="lazy"
          />
          <img
            v-else
            src="/core/assets/img/banners_cat_placeholder.jpg"
            data-src="/core/assets/img/banner_1.jpg"
            alt=""
            class="lazy"
          />
          <div
            class="short_info opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.5)"
          >
            <h3>{{ data.name }}</h3>
            <div>
              <span class="btn_1">{{ pageCms.buttonText }}</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <!--/banners_grid -->

    <div
      class="container margin_60_35"
      v-if="featuredProductList && featuredProductList.length > 0"
    >
      <div class="main_title">
        <h2>{{ pageCms.featuredProductTitle }}</h2>
        <span>{{ pageCms.featuredProductBackgroundText }}</span>
        <p>{{ pageCms.featuredProductSubtitle }}</p>
      </div>
      <div class="row small-gutters">
        <div
          class="col-6 col-md-4 col-xl-3"
          v-for="(data, index) in featuredProductList"
          :key="index"
        >
          <div class="grid_item">
            <figure>
              <span class="ribbon off" v-if="data.discountTitle">{{
                data.discountTitle
              }}</span>
              <a
                href="javascript:void(0)"
                @click="$router.push('/product-details/' + data.id)"
                v-if="data.featuredImage"
              >
                <img
                  class="img-fluid lazy"
                  :src="$imageURL + 'productImage/' + data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  alt=""
                />
                <img
                  class="img-fluid lazy"
                  :src="$imageURL + 'productImage/' + data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" v-else>
                <img
                  class="img-fluid lazy"
                  src="/core/assets/img/products/shoes/1_b.jpg"
                  data-src="/core/assets/img/products/shoes/1.jpg"
                  alt=""
                />
                <img
                  class="img-fluid lazy"
                  src="/core/assets/img/products/shoes/1_b.jpg"
                  data-src="/core/assets/img/products/shoes/1_b.jpg"
                  alt=""
                />
              </a>
            </figure>
            <!-- <div class="rating">
              <i class="icon-star voted"></i><i class="icon-star voted"></i
              ><i class="icon-star voted"></i><i class="icon-star voted"></i
              ><i class="icon-star"></i>
            </div> -->
            <span>{{ data.brandName }}</span>
            <br />
            <a
              href="javascript:void(0)"
              @click="$router.push('/product-details/' + data.id)"
            >
              <h3>{{ data.name }}</h3>
            </a>
            <div class="price_box">
              <span class="new_price"
                >${{
                  data.discountedPrice ? data.discountedPrice : data.price
                }}</span
              >
              <span class="old_price" v-if="data.discountedPrice"
                >${{ data.price }}</span
              >
            </div>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  class="tooltip-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to favorites"
                  @click="processWishListData(data, 1)"
                  ><i class="ti-heart"></i><span>Add to favorites</span></a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="processCartData(data, 1)"
                  class="tooltip-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to cart"
                  ><i class="ti-shopping-cart"></i><span>Add to cart</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="pageCms && Object.keys(pageCms).length != 0"
      class="featured lazy"
      :style="{
        'background-image': `url(${imageURL}home-page-cms/${pageCms.brandBackgroundImage})`,
      }"
    >
      <div
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.5)"
      >
        <div class="container margin_60">
          <div class="row justify-content-center justify-content-md-start">
            <div class="col-lg-6 wow" data-wow-offset="150">
              <h3>{{ singleProduct.name }}</h3>
              <p v-html="singleProduct.shortDescription"></p>
              <div class="feat_text_block">
                <div class="price_box">
                  <span class="new_price"
                    >${{
                      singleProduct.discountedPrice
                        ? singleProduct.discountedPrice
                        : singleProduct.price
                    }}</span
                  >
                  <span class="old_price" v-if="singleProduct?.discountedPrice"
                    >${{ singleProduct.price }}</span
                  >
                </div>
                <a
                  class="btn_1"
                  href="javascript:void(0)"
                  @click="$router.push('/product-details/' + singleProduct.id)"
                  role="button"
                  >{{ pageCms.buttonText }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="main_title" v-if="pageCms && Object.keys(pageCms).length != 0">
      <h2>{{ pageCms.instaPostTitle }}</h2>
      <span>{{ pageCms.instaPostBackgroundText }}</span>
      <p>{{ pageCms.instaPostSubtitle }}</p>
    </div>
    <div
      v-if="pageCms && Object.keys(pageCms).length != 0"
      class="featured lazy"
      :style="{
        'background-image': `url(${imageURL}home-page-cms/${pageCms.brandBackgroundImage})`,
      }"
    >
      <div
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.5)"
      >
        <div class="container margin_60">
          <carousel
            :itemsToShow="5"
            :wrapAround="true"
            :settings="settings"
            :breakpoints="breakpoints"
            :autoplay="2000"
            pause-autoplay-on-hover
          >
            <slide v-for="(data, index) in instagramFeedList" :key="index">
              <div class="carousel__item">
                <a :href="data.link" target="_blank">
                  <img
                    :src="$imageURL + 'instagram-feed/' + data.image"
                    alt=""
                    height="250"
                    width="210"
                    class="m-2"
                  />
                </a>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>

    <div
      class="container margin_60_35"
      v-if="bestSellingList && bestSellingList.length > 0"
    >
      <div class="main_title">
        <h2>{{ pageCms.topSellingTitle }}</h2>
        <span>{{ pageCms.topSellingBackgroundText }}</span>
        <p>{{ pageCms.topsellingSubtitle }}</p>
      </div>
      <div class="row small-gutters">
        <div
          class="col-6 col-md-4 col-xl-3"
          v-for="(data, index) in bestSellingList"
          :key="index"
        >
          <div class="grid_item">
            <figure>
              <span class="ribbon off" v-if="data.discountTitle">{{
                data.discountTitle
              }}</span>
              <a
                href="javascript:void(0)"
                @click="$router.push('/product-details/' + data.id)"
                v-if="data.featuredImage"
              >
                <img
                  class="img-fluid lazy"
                  :src="$imageURL + 'productImage/' + data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  :alt="data.featuredImage"
                />
                <img
                  class="img-fluid lazy"
                  :src="$imageURL + 'productImage/' + data.featuredImage"
                  :data-src="$imageURL + 'productImage/' + data.featuredImage"
                  :alt="data.featuredImage"
                />
              </a>
              <a href="javascript:void(0)" v-else>
                <img
                  class="img-fluid lazy"
                  src="/core/assets/img/products/shoes/1_b.jpg"
                  data-src="/core/assets/img/products/shoes/1.jpg"
                />
                <img
                  class="img-fluid lazy"
                  src="/core/assets/img/products/shoes/1_b.jpg"
                  data-src="/core/assets/img/products/shoes/1_b.jpg"
                />
              </a>
            </figure>
            <!-- <div class="rating">
              <i class="icon-star voted"></i><i class="icon-star voted"></i
              ><i class="icon-star voted"></i><i class="icon-star voted"></i
              ><i class="icon-star"></i>
            </div> -->
            <span>{{ data.brandName }}</span>
            <br />
            <a
              href="javascript:void(0)"
              @click="$router.push('/product-details/' + data.id)"
            >
              <h3>{{ data.name }}</h3>
            </a>
            <div class="price_box">
              <span class="new_price"
                >${{
                  data.discountedPrice ? data.discountedPrice : data.price
                }}</span
              >
              <span class="old_price" v-if="data?.discountedPrice"
                >${{ data.price }}</span
              >
            </div>
            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  class="tooltip-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to favorites"
                  @click="processWishListData(data, 1)"
                  ><i class="ti-heart"></i><span>Add to favorites</span></a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  class="tooltip-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to cart"
                  @click="processCartData(data, 1)"
                  ><i class="ti-shopping-cart"></i><span>Add to cart</span></a
                >
              </li>
            </ul>
          </div>
          <!-- /grid_item -->
        </div>

        <!-- /col -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->

    <!-- review section -->
    <div
      v-if="customerReviewList && customerReviewList.length > 0"
      class="container margin_60_35"
    >
      <div class="main_title">
        <h2>{{ pageCms.customerReviewTitle }}</h2>
        <span>{{ pageCms.customerReviewBackgroundText }}</span>
        <p>{{ pageCms.customerReviewSubtitle }}</p>
      </div>
      <div class="bg_gray">
        <div class="container margin_30">
          <!-- <div id="brands" class="owl-carousel owl-theme">
            <div
              class="item"
              v-for="(data, index) in customerReviewList"
              :key="index"
            ></div>
          </div> -->
          <carousel
            :itemsToShow="5"
            :wrapAround="true"
            :settings="settings"
            :breakpoints="breakpoints"
            :autoplay="2000"
            pause-autoplay-on-hover
          >
            <slide v-for="(data, index) in customerReviewList" :key="index">
              <div
                class="card text-center m-2"
                style="background-color: #f8f9fa; min-width: 6cm !important"
              >
                <div class="card-header" style="background-color: #f8f9fa">
                  <h6 style="font-size: 12px">
                    <span class="rating" style="color: #004dda"
                      ><i class="icon-star voted"></i
                      ><em>{{ data.rating ? data.rating : 0 }}/5</em></span
                    >
                  </h6>
                  <h6
                    class="card-subtitle mb-2 text-muted"
                    style="color: #6c757d; font-size: 15px"
                  >
                    {{ data.reviewerName ? data.reviewerName : "N/A" }}
                  </h6>
                </div>
                <div class="card-body" style="height: 80px; overflow: hidden">
                  <h5
                    class="card-text"
                    style="color: #6c757d; font-size: 13px; line-height: 20px"
                  >
                    {{
                      data.reviewDescription.length > 75
                        ? data.reviewDescription.substring(0, 80) + ".."
                        : data.reviewDescription
                    }}
                  </h5>
                </div>
              </div>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>

    <!-- <div
      class="container margin_60_35"
      v-if="instagramFeedList && instagramFeedList.length > 0"
    >
      <div class="main_title">
        <h2>{{ pageCms.instaPostTitle }}</h2>
        <span>{{ pageCms.instaPostBackgroundText }}</span>
        <p>{{ pageCms.instaPostSubtitle }}</p>
      </div>
      <div class="bg_gray">
        <div class="container margin_30">
          <carousel
            :itemsToShow="5"
            :wrapAround="true"
            :settings="settings"
            :breakpoints="breakpoints"
            :autoplay="2000"
            pause-autoplay-on-hover
          >
            <slide v-for="(data, index) in instagramFeedList" :key="index">
              <div class="carousel__item">
                <a :href="data.link" target="_blank">
                  <img
                    :src="$imageURL + 'instagram-feed/' + data.image"
                    alt=""
                    height="250"
                    width="210"
                    class="m-2"
                  />
                </a>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div> -->

    <div class="container margin_60_35">
      <div class="main_title">
        <h2>{{ pageCms.homeBlogTitle }}</h2>
        <span>{{ pageCms.homeBlogBackgroundText }}</span>
        <p>{{ pageCms.homeBlogSubTitle }}</p>
      </div>
      <div class="row" v-if="blogList.length > 0">
        <div class="col-lg-6" v-for="(data, index) in blogList" :key="index">
          <a
            class="box_news"
            href="javascript:void(0)"
            @click="$router.push('/blog-details/' + data.id)"
          >
            <figure>
              <img
                :src="imageURL + 'blog/' + data.photo"
                :data-src="imageURL + 'blog/' + data.photo"
                alt=""
                width="400"
                height="266"
                class="lazy"
              />
            </figure>
            <ul>
              <li>by {{ data.postedBy }}</li>
              <li>{{ this.$filters.formatDate(data.createdAt) }}</li>
            </ul>
            <h4>{{ data.title }}</h4>
            <p v-html="data.shortDetails"></p>
          </a>
        </div>
      </div>
    </div>
  </main>
  <Advertise
    v-if="state.advertiseList.includes('Home') && state.isAdvertise == true"
  />
  <NewsLetter
    v-if="state.newsletterList.includes('Home') && state.isNewsLetter == true"
  />
</template>
<script src="../js/home.js"></script>

<style scoped>
.boxs {
  width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-img {
  display: block;
  width: 30%;
  border-radius: 50%;
  border: 0 solid #e5e5e5;
  padding: 0;
}
.card .card-block {
  max-height: 300px;
  overflow: auto;
}

.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>