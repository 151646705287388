<template>
  <div v-if="spin" class="spin" style="overflow:hidden !important;">
    <div class="ring">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <div style="color: white; font-size: 19px; margin-left: -12px">
        Please wait...
      </div>
    </div>
  </div>
</template>
<script src="../js/loader.js"></script>
<style scoped>
@import "../css/loader.css";
</style>