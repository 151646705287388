
import siderbar from '../template/SideBar';
import { authHeader } from '@/auth';
export default {
    components:{
        siderbar
    },
    data() {
        return {
            orderDetails: {},
            orders: [],
            orderId: this.$route.params.id,
            email: localStorage.getItem('email'),
            mobile: localStorage.getItem('mobile'),
            customerInfo: [],
            imagePath: this.$imageURL + 'productImage/'
        }
    },
    async mounted () {
        await this.getOrderDetails();
    },
    methods: {
        getOrderDetails: async function() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderURL + '/' + this.orderId,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.orderDetails = response.data.Orders[0];
                        this.customerInfo = JSON.parse(response.data.Orders[0].shippingAddress);
                        this.orders = response.data.Orders[0].orders;
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "No Order found with this order id "
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        }
    },
    
}