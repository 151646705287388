<template>
  <main class="bg_gray">
    <div class="top_banner">
      <div
        class="opacity-mask d-flex align-items-center"
        data-opacity-mask="rgba(0, 0, 0, 0.3)"
      >
        <div class="container">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/')">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/location')">Our Location</a>
              </li>
              <li>
                <a href="javascript:void(0)">{{data.location}}</a>
              </li>
            </ul>
          </div>
          <h1>{{  data.location }}</h1>
        </div>
      </div>
      <img
        :src="imageURL + state.locationCms.backgroundImage"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="bg_white">
      <div class="container-fluid py-5">
        <div class="row justify-content-center m-0"  style="padding-top: 35px; padding-bottom: 35px">
          <div
            class="
              col-12 col-lg-9
              p-0
              d-flex
              flex-column flex-md-row
              justify-content-center
              shadow
              rounded-5
            "
          >
            <div class="left-form px-3 py-3" style="background-color:#e7e7e7">
              <h4
                class="pb-3"
                style="
                  text-align: center;
                  margin-bottom: 35px;
                  margin-top: 5px;
                  font-size: 28px;
                  font-weight: 700;
                "
              >
                {{  state.locationCms.enqueryTitle }}
              </h4>
              <div class="form-group">
                <label>Name: </label>
                <input
                  class="form-control"
                  v-model="name"
                  type="text"
                  placeholder="Name *"
                />
              </div>
              <div class="form-group">
                <label>Email: </label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  placeholder="Email *"
                />
              </div>
              <div class="form-group">
                <label>Phone: </label>
                <input
                  class="form-control"
                  v-model="phone"
                  type="phone"
                  placeholder="Phone *"
                />
              </div>
              <div class="form-group">
                <label>How did you hear about us? </label>
                <select
                  v-model="media"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option style="display: 'none'" value="" selected>
                    -- Please Select --
                  </option>
                  <option v-for="(data, index) in state.hearAboutUsOptions" :key="index" :value="data">{{data}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Questions / Comments: </label>
                <textarea
                  class="form-control"
                  v-model="comments"
                  placeholder="Questions / Comments *"
                ></textarea>
              </div>
           
                <input
                  class="btn_1 full-width"
                  @click="sendMessage"
                  :value="state.locationCms.buttonText"
                  type="button"
                />
        
            </div>

            <div class="right-form px-3 py-3 bg-primary">
              <div class="">
                <h2
                  class="text-white"
                  style="
                    text-align: center;
                    margin-bottom: 35px;
                    margin-top: 5px;
                    font-size: 28px;
                    font-weight: 700;
                  "
                >
                  Location Details
                </h2>
                <div class="text-normal">
                  <p class="">
                    <i class="fa-solid fa-house"> </i> {{ data.location }}
                  </p>
                  <p class="">
                    <i class="fa-solid fa-square-phone"></i>
                    {{ data.contactNumber }}
                  </p>
                  <p class="">
                    <i class="fa-solid fa-envelope"> </i>
                    {{ data.email }}
                  </p>
                  <p class="">
                    <i class="far fa-clock"></i>
                    {{ data.timeFrame }}
                  </p>
                  <iframe
                    :src="data.mapLink"
                    width="100%"
                    height="330"
                    frameborder="0"
                    style="border: 0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script src="../js/location-details.js"></script>
<style scoped>
.text-white {
  color: #ffffff;
}
.bg-primary {
  background: #004dda;
}
.left-form,
.right-form {
  width: 100%;
}
.left-form {
  border-radius: 15px 0 0 15px;
}
.right-form {
  border-radius: 0 15px 15px 0;
  color: #ffffff;
}
.rounded-5 {
  border-radius: 15px;
}
@media (max-width: 767px) {
  .left-form {
    border-radius: 15px 15px 0 0;
  }
  .right-form {
    border-radius: 0 0 15px 15px;
  }
}
</style>
