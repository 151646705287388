<template>
  <main class="bg_gray">
    <div class="container margin_30">
      <div class="row">
        <siderbar />
        <div class="col-lg-9">
          <div class="card mb-4">
            <header class="card-header">
              <h4 class="card-title">Shipped Orders</h4>
            </header>
            <div class="card-body" v-if="orderList.length > 0">
              <div class="table-responsive">
                <div class="table-responsive">
                  <table class="table align-middle table-nowrap mb-0">
                    <thead class="table-light">
                      <tr>
                        <th class="align-middle" scope="col">Order ID</th>
                        <th class="align-middle" scope="col">Billing Name</th>
                        <th class="align-middle" scope="col">Date</th>
                        <th class="align-middle" scope="col">Total</th>
                        <th class="align-middle" scope="col">Payment Status</th>
                        <th class="align-middle" scope="col">Payment Method</th>
                        <th class="align-middle" scope="col">View Details</th>
                      </tr>
                    </thead>
                    <tbody style="border-top: 0px">
                      <tr v-for="(data, index) in orderList" :key="index">
                        <td>
                          <a
                            href="javascript:void(0)"
                            @click="
                              $router.push('/order-details/' + data.orderId)
                            "
                            class="fw-bold"
                            >#{{ data.orderId }}</a
                          >
                        </td>
                        <td>{{ data.customerName }}</td>
                        <td>{{ $filters.formatDate(data.createdAt) }}</td>
                        <td>
                          ${{
                            data.couponDiscount != "0"
                              ? data.total - parseInt(data.couponDiscount)
                              : data.total
                          }}
                        </td>
                        <td>
                          <span
                            :class="[
                              'badge badge-pill',
                              data.paymentStatus == 'PENDING'
                                ? 'badge-soft-warning'
                                : data.paymentStatus == 'Paid'
                                ? 'badge-soft-success'
                                : 'badge-soft-danger',
                            ]"
                            >{{ data.paymentStatus.toUpperCase() }}</span
                          >
                        </td>
                        <td>
                          <i
                            class="
                              material-icons
                              md-payment
                              font-xxl
                              text-muted
                              mr-5
                            "
                          ></i>
                          {{ data.paymentMethodName }}
                        </td>
                        <td>
                          <router-link
                            :to="'/order-details/' + data.orderId"
                            class="btn btn-dash-xs"
                          >
                            View details</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- table-responsive end// -->
            </div>
            <div class="card-body" v-else>
              <p class="text-center">No data found.</p>
            </div>
            <nav
              class="page-navigation justify-content-center d-flex"
              aria-label="page-navigation"
            >
              <v-pagination
                v-if="orderList.length > 0"
                v-model="page"
                @update:modelValue="updateHandler"
                :pages="totalPage"
                :range-size="1"
                active-color="#FF8400"
              ></v-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
@import url(../css/style.css);
@import url("../../../assets/pagination.css");
</style>
<script src="../js/shipped-orders.js"></script>