import { authHeader } from "../../../auth";
import { state } from "@/globalVariables";
export default {
    name: "Location",
    data() {
        return {
            state,
            dataList: [],
            imageURL: this.$imageURL+'location-cms/'
        }
    },
    async mounted() {
        document.title = "Location";
        await this.fetchData();
    },
    methods: {
        fetchData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.location.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
