
import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import { state } from '@/globalVariables';
import NewsLetter from '../../Newsletter/template/NewsLetter';
import Advertise from '../../Advertise/template/Advertise';
export default {
    components: {
        Loading,
        NewsLetter,
        Advertise
    },
    data() {
        return {
            state,
            pageCMS: {},
            contentData: [],
            name: '',
            email: '',
            message: ''
        }
    },
    created() {
        this.scrollToTop();
    },
    async mounted() {
        await this.getContactData();
        this.loadJSCSS();
    },
    methods: {
        loadJSCSS: function () {
            var styles = [
                '/core/assets/css/contact.css'
            ]
            styles.forEach(style => {
                let tag = document.createElement('link');
                tag.type = "text/css";
                tag.rel = "stylesheet";
                tag.href = style;
                document.head.appendChild(tag);
            });
            var scripts = [
                "/core/assets/js/main.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);

            });
        },
        getContactData: async function () {

            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contact.contactPage
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {

                    this.pageCMS = await response.data.data.cmsData;
                    this.contentData = await response.data.data.contentData;

                }
            }).catch(error => {
                console.log(error);
            });

        },
        sendMessage: async function () {
            if (!this.name || !this.email || !this.message) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please Enter your name, email and message!"
                });
            }
            else {
                let data = {
                    name: this.name,
                    email: this.email,
                    message: this.message
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.contact.sendMessage,
                    headers: {
                        "Authorization": authHeader(),
                    }

                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 201) {

                        this.$swal.fire({
                            icon: "success",
                            text: "Your message has been send."
                        });
                        this.name = '';
                        this.email = '';
                        this.message = '';
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}